import { httpsCallable } from "firebase/functions";
import { bizFunctions } from "lib/firebase";
import { SaveBizEstimates } from "@shared/types/functionParams";

type RequestParams = SaveBizEstimates.RequestParams;

export const saveBizEstimates = async (
  requestParams: RequestParams
): Promise<void> => {
  const func = httpsCallable(bizFunctions, "onCallSaveBizEstimates");

  await func(requestParams);
};
