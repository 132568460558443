import { useEffect, useState } from "react";

import {
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import ErrorIcon from "@mui/icons-material/Error";
import { Swiper, SwiperSlide } from "swiper/react";

import useCategorizedBizEstimates from "../hooks/useCategorizedEstimates";
import { BizEstimate, GentyoImage } from "@shared/types/entities/owner/BizEstimate";
import { UpdateProjectByStatus } from "@shared/types/functionParams";
import { updateProjectByStatus } from "../api/updateProjectByStatus";
import { useSnackbar } from "context/snackbarContext";
import { useStore } from "@nanostores/react";
import { BizConstractorStore } from "store/nanostores/contractorInfo";
import { Project } from "@shared/types/entities/owner/Project";
import { useBackdrop } from "context/backdropContext";
import { BizEstimateCalc, displayTaxType, getValidityPeriod } from "@shared/utils";
import { useNavigate, useParams } from "react-router-dom";
import { OwnerCommentTooltip } from "./OwnerCommentTooltip";

type CompleteConstructionConfirmDialogProps = {
  open: boolean;
  onClose: () => void;
  project: Project;
};
const CompleteConstructionConfirmDialog = ({ open, onClose, project }: CompleteConstructionConfirmDialogProps) => {
  const { userId } = useStore(BizConstractorStore.IDMap);
  const { setBackdrop } = useBackdrop();
  const { showSnackbar } = useSnackbar();

  const handleComplete = async (): Promise<void> => {
    setBackdrop(true);

    const updateProjectByStatusRequestParams: UpdateProjectByStatus.RequestParams = {
      status: "検収待ち",
      companyId: project.companyId,
      projectId: project.id!,
      bizUserId: userId,
    };

    try {
      await updateProjectByStatus(updateProjectByStatusRequestParams);
      showSnackbar("工事を完了しました。", "success");
    } catch (e) {
      showSnackbar("エラーが発生しました", "error");
      console.log(e);
    }

    setBackdrop(false);
    onClose();
  };

  const hasBizAfterImage: boolean = Boolean(project.bizAfterImageURLs && project.bizAfterImageURLs.length);

  return (
    <Dialog
      onClose={onClose}
      open={open}
      BackdropProps={{
        style: {
          backgroundColor: "rgba(0, 0, 0, 0.8)",
        },
      }}
    >
      <Box className="flex flex-col items-center p-3">
        <ErrorIcon className="text-5xl text-remodela-green" />
        <Box className="font-bold text-2xl">確認</Box>

        <Box className="flex flex-col items-center text-sm my-4">
          <Box>工事を完了します。よろしいですか？</Box>
          <Box>一度完了した場合、見積の追加をすることはできません。</Box>
          {!hasBizAfterImage && <Box className="text-remodela-error">※工事後写真が登録されていません。</Box>}
        </Box>

        <Box className="flex justify-around w-full">
          <Button
            variant="outlined"
            className="font-bold bg-white text-darkBlueGray border-2 border-mediumGray w-28"
            onClick={onClose}
          >
            キャンセル
          </Button>
          <Button variant="contained" className="font-bold bg-remodela-green w-28" onClick={handleComplete}>
            完了する
          </Button>
        </Box>
      </Box>
    </Dialog>
  );
};

const ZoomGentyoImageDialog = ({
  open,
  onClose,
  gentyoImages,
}: {
  open: boolean;
  onClose: () => void;
  gentyoImages: GentyoImage[];
}): JSX.Element => {
  const [zoomGentyoImageURL, setZoomGentyoImageURL] = useState<string>();

  useEffect(() => {
    setZoomGentyoImageURL(gentyoImages[0].URL);
  }, [gentyoImages]);

  const handleClickSlide = (gentyoImageURL: string) => {
    setZoomGentyoImageURL(gentyoImageURL);
  };

  return (
    <Dialog
      onClose={onClose}
      open={open}
      maxWidth="xl"
      PaperComponent={Paper}
      PaperProps={{ className: "rounded-2xl" }}
    >
      <DialogTitle className="flex justify-between items-center">
        <Box className="font-bold">現地調査写真</Box>
      </DialogTitle>

      <DialogContent>
        <Box className="w-[16rem] h-[12rem] sm:w-[40rem] sm:h-[30rem]">
          <img src={zoomGentyoImageURL} alt="gentyoImage" className="h-full w-full object-contain" />
        </Box>

        <Box className=" sm:w-[40rem] sm:h-32">
          <Swiper slidesPerView={4} spaceBetween={5} navigation className="h-full">
            {gentyoImages?.map((gentyoImage, index) => {
              return (
                <SwiperSlide key={index} className="h-28 my-2">
                  <Box
                    className="cursor-pointer h-full w-full border-[1px] border-mediumGray mb-3"
                    onClick={() => {
                      handleClickSlide(gentyoImage.URL);
                    }}
                  >
                    <img src={gentyoImage.URL} alt="" className="h-full w-full object-contain" />
                  </Box>
                </SwiperSlide>
              );
            })}
          </Swiper>
        </Box>
      </DialogContent>
    </Dialog>
  );
};

type HeaderInfo = {
  name: string;
  width: number;
};

const table = {
  gentyoWidth: 130,
  itemWidth: 250,
  quantityWidth: 80,
  unitPriceWidth: 80,
  amountWidth: 80,
  noteWidth: 290,
  ownerCommentsWidth: 50,
  ownerFixedWidth: 50,
  get allWidth(): number {
    return (
      this.gentyoWidth +
      this.itemWidth +
      this.quantityWidth +
      this.unitPriceWidth +
      this.amountWidth +
      this.noteWidth +
      this.ownerCommentsWidth +
      this.ownerFixedWidth
    );
  },
  headerInfos: (): HeaderInfo[] => {
    return [
      { name: "現調写真", width: table.gentyoWidth },
      { name: "項目", width: table.itemWidth },
      { name: "数量", width: table.quantityWidth },
      { name: "単価", width: table.unitPriceWidth },
      { name: "金額", width: table.amountWidth },
      { name: "備考", width: table.noteWidth },
      { name: "コメント", width: table.ownerCommentsWidth },
      { name: "承認", width: table.ownerFixedWidth },
    ];
  },
};

/**見積データテーブル表示*/
interface EstimateContentProps {
  project: Project;
  bizEstimates: BizEstimate[];
}
const BizEstimateContent = ({ project, bizEstimates }: EstimateContentProps): JSX.Element => {
  const [completeConstructionConfirmDialogOpen, setCompleteConstructionConfirmDialogOpen] = useState<boolean>(false);
  const [gentyoImageDialogOpen, setGentyoImageDialogOpen] = useState<boolean>(false);
  const [gentyoImages, setGentyoImages] = useState<GentyoImage[]>();

  const { companyId, projectId } = useParams();
  const navigate = useNavigate();

  const { userId } = useStore(BizConstractorStore.IDMap);
  const { showSnackbar } = useSnackbar();
  const {
    categorizedEstimatesList,
    getGentyoImageURLCount,
    getFirstGentyoImageURL,
    getItemName,
    getQuantity,
    getUnitPrice,
    getAmount,
  } = useCategorizedBizEstimates(bizEstimates);

  const bizEstimateCalc = BizEstimateCalc(bizEstimates, project.bizTaxType);

  /**現調写真クリック */
  const handleClickGentyoImage = (gentyoImages?: GentyoImage[]): void => {
    if (!gentyoImages || gentyoImages.length === 0) {
      return;
    }

    setGentyoImages(gentyoImages);
    setGentyoImageDialogOpen(true);
  };

  /**現調写真モーダルを閉じる */
  const handleGentyoDialogClose = (): void => setGentyoImageDialogOpen(false);

  /**見積提案ボタン */
  const handleProposalEstimate = async (): Promise<void> => {
    const updateProjectByStatusRequestParams: UpdateProjectByStatus.RequestParams = {
      status: "オファー可能",
      companyId: project.companyId,
      projectId: project.id!,
      bizUserId: userId,
    };
    await updateProjectByStatus(updateProjectByStatusRequestParams);

    showSnackbar("見積を提案しました。", "success");
  };

  /**工事完了ボタン */
  const handleCompleteConstruction = (): void => {
    setCompleteConstructionConfirmDialogOpen(true);
  };

  /**工事完了確認モーダルを閉じる */
  const handleCompleteConstructionConfirmDialogClose = (): void => {
    setCompleteConstructionConfirmDialogOpen(false);
  };

  return (
    <>
      <Paper className="p-4">
        <Box className="flex items-center justify-between">
          <Box className="font-bold">見積内容</Box>

          <Box>
            {project.status === "見積中" && (
              <Button
                variant="contained"
                className="bg-remodela-green rounded-md font-bold"
                onClick={handleProposalEstimate}
              >
                見積を提案する
              </Button>
            )}

            {["見積中", "オファー可能"].includes(project.status) && (
              <Button
                variant="contained"
                className="bg-remodela-green rounded-md font-bold ml-5"
                onClick={() => {
                  navigate(`/companies/${companyId}/projects/${projectId}/estimates`);
                }}
              >
                見積を編集する
              </Button>
            )}

            {project.status === "工事中" && (
              <Button
                variant="contained"
                className="bg-remodela-green rounded-md font-bold"
                onClick={handleCompleteConstruction}
              >
                工事を完了する
              </Button>
            )}
          </Box>
        </Box>

        <Box className="flex flex-col sm:flex-row sm:items-center">
          <Box className="text-pink font-bold">{`¥${bizEstimateCalc.getIncludeTaxTotalAmount().toLocaleString()}`}</Box>

          <Box className="flex flex-col sm:flex-row text-sm sm:px-2">
            <Box className="mr-2">{`小計: ¥${bizEstimateCalc.getTotalAmount.toLocaleString()}`} </Box>
            <Box className="mr-2">{`${displayTaxType(project.bizTaxType)}: ¥${bizEstimateCalc
              .getTaxAmount()
              .toLocaleString()}`}</Box>
            <Box>{`有効期限: ${getValidityPeriod(project.bizEstimateValidityPeriod)}`}</Box>
          </Box>
        </Box>

        {categorizedEstimatesList.map((categorizedEstimates, index) => {
          const categoryBoxStyle =
            categorizedEstimates.categoryName === "お値引き"
              ? "bg-lightPink text-remodela-error"
              : "bg-winterGreen text-remodela-green";

          return (
            <Box key={index} className="my-4">
              <Box className={`w-full font-bold p-3 ${categoryBoxStyle}`}>{categorizedEstimates.categoryName}</Box>

              <TableContainer component={Paper}>
                <Table size="small" className={`min-w-[1392px]`}>
                  <TableHead>
                    <TableRow>
                      <TableCell className="bg-lightGray text-darkGray font-bold text-center text-sm min-w-[130px] max-w-[130px]">
                        現調写真
                      </TableCell>
                      <TableCell className="bg-lightGray text-darkGray font-bold text-center text-sm w-4/12">
                        項目
                      </TableCell>
                      <TableCell className="bg-lightGray text-darkGray font-bold text-center text-sm w-1/12">
                        数量
                      </TableCell>
                      <TableCell className="bg-lightGray text-darkGray font-bold text-center text-sm w-1/12">
                        単価
                      </TableCell>
                      <TableCell className="bg-lightGray text-darkGray font-bold text-center text-sm w-1/12">
                        金額
                      </TableCell>
                      <TableCell className="bg-lightGray text-darkGray font-bold text-center text-sm w-3/12">
                        備考
                      </TableCell>
                      <TableCell className="bg-lightGray text-darkGray font-bold text-center text-sm w-1/12">
                        コメント
                      </TableCell>
                      <TableCell className="bg-lightGray text-darkGray font-bold text-center text-sm w-1/12">
                        承認
                      </TableCell>
                    </TableRow>
                  </TableHead>

                  <TableBody>
                    {categorizedEstimates.bizEstimates.map((bizEstimate) => {
                      const gentyoImageURLCount = getGentyoImageURLCount(bizEstimate);
                      const hasMultipleImages = gentyoImageURLCount >= 2;
                      const pointer = gentyoImageURLCount > 0 ? "cursor-pointer" : "";

                      return (
                        <TableRow key={bizEstimate.id} className="even:bg-offWhite">
                          <TableCell
                            className={`relative text-center text-sm p-0 ${pointer}`}
                            onClick={() => {
                              handleClickGentyoImage(bizEstimate.gentyoImages);
                            }}
                          >
                            <Box className="h-16 w-[130px]">
                              <img
                                src={getFirstGentyoImageURL(bizEstimate)}
                                alt="noImage"
                                className="h-full w-full object-contain"
                              />
                              {hasMultipleImages && (
                                <Box className="absolute bottom-0 right-5 bg-pink text-white font-bold px-1 rounded">
                                  {`+${gentyoImageURLCount - 1}`}
                                </Box>
                              )}
                            </Box>
                          </TableCell>
                          <TableCell className={`text-sm h-16 w-[210px]`}>{getItemName(bizEstimate)}</TableCell>
                          <TableCell className={`text-right text-sm h-16 w-[100px]`}>
                            {getQuantity(bizEstimate)}
                          </TableCell>
                          <TableCell className={`text-right font-bold text-pink text-sm h-16 w-[100px]`}>
                            {getUnitPrice(bizEstimate)}
                          </TableCell>
                          <TableCell className={`text-right font-bold text-pink text-sm h-16 w-[100px]`}>
                            {getAmount(bizEstimate)}
                          </TableCell>
                          <TableCell className={`text-sm h-16 w-[230px]`}>{bizEstimate.note}</TableCell>
                          <TableCell className={`text-center text-sm h-16 w-[70px]`}>
                            {bizEstimate.ownerComments && bizEstimate.ownerComments.length > 0 && (
                              <OwnerCommentTooltip ownerComments={bizEstimate.ownerComments} />
                            )}
                          </TableCell>
                          <TableCell className={`text-center text-sm h-16 w-[70px]`}>
                            {bizEstimate.ownerFixed ? (
                              <Box className="font-bold text-base text-[#428C58]">済</Box>
                            ) : (
                              <Box className="font-bold text-base text-[#EC7988]">未</Box>
                            )}
                          </TableCell>
                        </TableRow>
                      );
                    })}
                  </TableBody>
                </Table>
              </TableContainer>
            </Box>
          );
        })}

        {project.status === "工事中" && (
          <Box className="flex items-center justify-center border-2 border-dashed border-mediumGray p-3 bg-white">
            <p className="text-sm">新たに見積を追加する場合</p>
            <Button
              variant="contained"
              className="bg-remodela-green rounded-md font-bold mx-4"
              onClick={() => {
                navigate(`/companies/${companyId}/projects/${projectId}/estimates`);
              }}
            >
              見積追加に進む
            </Button>
          </Box>
        )}
      </Paper>

      {gentyoImages && (
        <ZoomGentyoImageDialog
          open={gentyoImageDialogOpen}
          onClose={handleGentyoDialogClose}
          gentyoImages={gentyoImages}
        />
      )}

      <CompleteConstructionConfirmDialog
        open={completeConstructionConfirmDialogOpen}
        onClose={handleCompleteConstructionConfirmDialogClose}
        project={project}
      />
    </>
  );
};

interface BizEstimatesAreaProps {
  project: Project;
  bizEstimates: BizEstimate[];
}
export const BizEstimatesArea = ({ project, bizEstimates }: BizEstimatesAreaProps): JSX.Element => {
  if (bizEstimates.length === 0) {
    return <NoEstimateContent />;
  }

  return <BizEstimateContent project={project} bizEstimates={bizEstimates} />;
};

/**見積データなし*/
const NoEstimateContent = (): JSX.Element => {
  const { companyId, projectId } = useParams();
  const navigate = useNavigate();

  return (
    <>
      <Paper className="p-4">
        <Box className="font-bold">見積内容</Box>

        <Box className="flex flex-col justify-center items-center h-48 border border-mediumGray bg-lightGray rounded-lg">
          <Box className="text-sm mb-2">見積はまだ作成されていません。</Box>

          <Button
            onClick={() => {
              navigate(`/companies/${companyId}/projects/${projectId}/estimates`);
            }}
            variant="contained"
            className="bg-remodela-green rounded-xl font-bold"
          >
            見積を作成する
          </Button>
        </Box>
      </Paper>
    </>
  );
};
