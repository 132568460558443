import { Box, Typography } from "@mui/material";
import workerIcon from '../../../assets/images/worker.png';
import calculatorIcon from '../../../assets/images/calculator.png';
import quickIcon from '../../../assets/images/quick.png';
import freeIcon from '../../../assets/images/free.png';

const PointSection = () => {
    const points = [
        {
            title: "高品質の職人",
            descriptionFirst: "評価と施工件数を",
            descriptionSecond: "確認できます",
            icon: workerIcon,
        },
        {
            title: "安心の価格",
            descriptionFirst: "職人と金額の",
            descriptionSecond: "交渉ができます",
            icon: calculatorIcon,
        },
        {
            title: "即マッチング",
            descriptionFirst: "最短当日",
            descriptionSecond: "マッチング",
            icon: quickIcon,
        },
        {
            title: "今だけ無料",
            descriptionFirst: "何件依頼しても",
            descriptionSecond: "利用料は０円",
            icon: freeIcon,
        },
    ];

    return (
        <Box className="flex flex-row items-center justify-center flex-wrap gap-4 sm:gap-5 md:gap-8 mt-5">
            {points.map((item, index) => (
                <Box
                    key={index}
                    className="bg-white rounded-full w-[40vw] md:w-[20vw] h-[40vw] md:h-[20vw] min-w-[120px] min-h-[120px] max-w-[250px] max-h-[250px] flex flex-col items-center justify-center p-4"
                >
                    <Typography
                        variant="h5"
                        className="font-bold text-[#10692A] text-center text-lg sm:text-xl md:text-2xl"
                    >
                        {item.title}
                    </Typography>
                    {item.icon && (
                        <Box
                            component="img"
                            src={item.icon}
                            alt="アイコン"
                            className="w-3/5 md:w-1/2 h-auto mt-2"
                        />
                    )}
                    <Typography
                        variant="body1"
                        className="text-black text-center mt-1 text-xs sm:text-sm md:text-base"
                    >
                        {item.descriptionFirst}
                    </Typography>
                    <Typography
                        variant="body1"
                        className="text-black text-center text-xs md:text-base"
                    >
                        {item.descriptionSecond}
                    </Typography>
                </Box>
            ))}
        </Box>
    );
};

export default PointSection;
