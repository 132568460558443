import { useState } from "react";
import {
  Autocomplete,
  Box,
  IconButton,
  InputAdornment,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextareaAutosize,
  TextField,
} from "@mui/material";
import DeleteOutlinedIcon from "@mui/icons-material/DeleteOutlined";
import UploadFileIcon from "@mui/icons-material/UploadFile";
import DragHandleIcon from "@mui/icons-material/DragHandle";
import { closestCenter, DndContext, DragEndEvent } from "@dnd-kit/core";
import {
  arrayMove,
  SortableContext,
  useSortable,
  verticalListSortingStrategy,
} from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";

import { UNITS } from "constants/constants";
import { GentyoFileUploadDialog } from "./Popup/GentyoFileUploadDialog";
import { OwnerCommentTooltip } from "./Popup/OwnerCommentTooltip";
import {
  BizEstimateRow,
  CategorizedEstimate,
  useCategorizedEstimatesContext,
} from "features/estimates/context/categorizedEstimatesContext";

/**内訳のヘッダー行*/
export const CustomTableHeaderRow = (): JSX.Element => {
  const headerCells = [
    { name: "", width: 60 },
    { name: "現調写真", width: 70 },
    { name: "項目", width: 350 },
    { name: "数量", width: 250 },
    { name: "単価", width: 140 },
    { name: "金額", width: 160 },
    { name: "備考", width: 354 },
    { name: "コメント", width: 100 },
    { name: "削除", width: 100 },
  ];

  return (
    <TableRow>
      {headerCells.map((cell) => (
        <TableCell
          key={cell.name}
          className={`bg-lightGray text-darkGray font-bold text-center text-sm w-[${cell.width}px]`}
        >
          {cell.name}
        </TableCell>
      ))}
    </TableRow>
  );
};

/**内訳のデータ行*/
type SortableTableRowProps = {
  row: BizEstimateRow;
  categoryId: string;
};
const SortableTableRow = ({
  row,
  categoryId,
}: SortableTableRowProps): JSX.Element => {
  const [gentyoFileDialogOpen, setGentyoFileDialogOpen] =
    useState<boolean>(false);

  const {
    deleteRow,

    changeItemName,
    changeQuantity,
    changeUnit,
    changeUnitPrice,
    changeNote,

    getFirstGentyoImageURL,
    getGentyoImageCount,
  } = useCategorizedEstimatesContext();

  const { attributes, listeners, setNodeRef, transform, transition } =
    useSortable({ id: row.id });

  const handleGentyoFileDialogOpen = (): void => setGentyoFileDialogOpen(true);
  const handleGentyoFileDialogClose = (): void =>
    setGentyoFileDialogOpen(false);

  const firstGentyoImageURL = getFirstGentyoImageURL(row);
  const gentyoImageCount = getGentyoImageCount(row);

  const getTableRowClassName = (): string => {
    if (row.ownerComments && row.ownerComments.length > 0) {
      return "bg-remodela-warmOrange bg-opacity-10 border-b-2 border-b-remodela-warmOrange";
    }

    return "";
  };

  return (
    <TableRow
      style={{
        transform: CSS.Transform.toString(transform),
        transition,
      }}
      key={row.id}
      ref={setNodeRef}
      className={getTableRowClassName()}
    >
      <TableCell
        {...attributes}
        {...listeners}
        className="text-center text-sm h-16 py-1 px-1 w-[60px]"
      >
        {!row.ownerFixed && <DragHandleIcon />}
      </TableCell>
      <TableCell className="relative text-center text-sm h-16 py-1 px-1 w-[70px]">
        {firstGentyoImageURL ? (
          <>
            <img
              src={firstGentyoImageURL}
              alt="noImage"
              className="h-full w-full object-contain cursor-pointer"
              onClick={handleGentyoFileDialogOpen}
            />
            {gentyoImageCount >= 2 && (
              <p className="absolute bottom-[5px] right-[5px] bg-pink text-white font-bold px-1 rounded">
                {`+${gentyoImageCount - 1}`}
              </p>
            )}
          </>
        ) : (
          <Box className="cursor-pointer" onClick={handleGentyoFileDialogOpen}>
            <UploadFileIcon className="bg-lightGray rounded-md" />
          </Box>
        )}

        {gentyoFileDialogOpen && (
          <GentyoFileUploadDialog
            open={gentyoFileDialogOpen}
            bizEstimateRow={row}
            categoryId={categoryId}
            handleDialogClose={handleGentyoFileDialogClose}
          />
        )}
      </TableCell>
      <TableCell className="text-center text-sm h-16 py-1 px-1 w-[350px]">
        {!row.ownerFixed ? (
          <TextField
            placeholder="洋室 / 壁クロス"
            value={row.itemName || ""}
            onChange={(e) =>
              changeItemName(categoryId, row.rowNo, e.target.value)
            }
            variant="outlined"
            size="small"
            className="w-full"
            InputProps={{
              sx: {
                backgroundColor: "white",
              },
            }}
          />
        ) : (
          <p>{row.itemName}</p>
        )}
      </TableCell>
      <TableCell className="text-center text-sm h-16 py-1 px-1 w-[250px]">
        {!row.ownerFixed ? (
          <div className="flex justify-center">
            <TextField
              placeholder="26.1"
              value={row.quantity || ""}
              onChange={(e) =>
                changeQuantity(categoryId, row.rowNo, e.target.value)
              }
              variant="outlined"
              size="small"
              type="number"
              className="w-[92px]"
              InputProps={{
                sx: {
                  backgroundColor: "white",
                },
              }}
            />
            <Autocomplete
              value={row.unit || ""}
              size="small"
              freeSolo
              onChange={(_, newValue) => {
                changeUnit(categoryId, row.rowNo, newValue || "");
              }}
              sx={{
                backgroundColor: "white",
              }}
              options={UNITS}
              renderInput={(params) => (
                <TextField
                  placeholder="m"
                  onChange={(e) =>
                    changeUnit(categoryId, row.rowNo, e.target.value)
                  }
                  {...params}
                />
              )}
              className="w-[150px]"
            />
          </div>
        ) : (
          <div>{`${row.quantity} ${row.unit}`}</div>
        )}
      </TableCell>
      <TableCell className="text-center text-sm h-16 py-1 px-1 w-[140px]">
        {!row.ownerFixed ? (
          <TextField
            placeholder="1000"
            value={row.unitPrice || ""}
            onChange={(e) =>
              changeUnitPrice(categoryId, row.rowNo, e.target.value)
            }
            type="number"
            variant="outlined"
            size="small"
            InputProps={{
              sx: {
                backgroundColor: "white",
              },
              startAdornment: (
                <InputAdornment position="start">¥</InputAdornment>
              ),
            }}
          />
        ) : (
          <div>{`¥${row.unitPrice?.toLocaleString()}`}</div>
        )}
      </TableCell>
      <TableCell className="text-center text-sm h-16 py-1 px-1 w-[160px]">
        {!row.ownerFixed ? (
          <TextField
            placeholder="26,100"
            value={row.amount?.toLocaleString() || ""}
            variant="outlined"
            size="small"
            InputProps={{
              sx: {
                backgroundColor: "white",
              },
              readOnly: true,
              startAdornment: (
                <InputAdornment position="start">¥</InputAdornment>
              ),
            }}
            className="pointer-events-none"
          />
        ) : (
          <div>{`¥${row.amount?.toLocaleString()}`}</div>
        )}
      </TableCell>
      <TableCell className="text-center text-sm h-16 py-1 px-1 w-[354px]">
        <TextareaAutosize
          placeholder="備考"
          value={row.note || ""}
          onChange={(e) => changeNote(categoryId, row.rowNo, e.target.value)}
          className="border-[1px] border-[#e0e0e0] rounded-lg py-[8.5px] px-[14px] w-full"
          minRows={1}
        />
      </TableCell>
      <TableCell className="text-center text-sm h-16 py-1 px-1 w-[100px]">
        {row.ownerComments && row.ownerComments.length > 0 && (
          <OwnerCommentTooltip ownerComments={row.ownerComments} />
        )}
      </TableCell>
      <TableCell className="text-center text-sm h-16 py-1 px-1 w-[100px]">
        {!row.ownerFixed && (
          <IconButton
            size="small"
            className="text-remodela-error"
            onClick={() => deleteRow(categoryId, row.rowNo)}
          >
            <DeleteOutlinedIcon />
          </IconButton>
        )}
      </TableCell>
    </TableRow>
  );
};

type DnDTableProps = {
  categorizedEstimate: CategorizedEstimate;
};
export const DnDTable = ({ categorizedEstimate }: DnDTableProps) => {
  const { changeRow } = useCategorizedEstimatesContext();
  const categoryId = categorizedEstimate.categoryId;

  const handleDragEnd = (event: DragEndEvent): void => {
    const { active, over } = event;

    if (!over) {
      return;
    }

    if (active.id === over.id) {
      return;
    }

    const bizEstimateRows = categorizedEstimate.bizEstimateRows;

    const oldIndex = bizEstimateRows.findIndex((row) => row.id === active.id);
    const newIndex = bizEstimateRows.findIndex((row) => row.id === over.id);

    const changedBizEstimateRows = arrayMove(
      bizEstimateRows,
      oldIndex,
      newIndex
    );

    changeRow(categoryId, changedBizEstimateRows);
  };

  return (
    <>
      <DndContext collisionDetection={closestCenter} onDragEnd={handleDragEnd}>
        <SortableContext
          items={categorizedEstimate.bizEstimateRows}
          strategy={verticalListSortingStrategy}
        >
          <TableContainer component={Paper} className="min-w-[1144px]">
            <Table aria-label="customized table">
              <TableHead>
                <CustomTableHeaderRow />
              </TableHead>
              <TableBody>
                {categorizedEstimate.bizEstimateRows.map((bizEstimateRow) => (
                  <SortableTableRow
                    key={bizEstimateRow.id}
                    row={bizEstimateRow}
                    categoryId={categoryId}
                  />
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </SortableContext>
      </DndContext>
    </>
  );
};
