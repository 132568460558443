import { BizUser } from "@shared/types/entities/owner/BizUser";
import { atom, map } from "nanostores";

type ContractorInfo = {
  bizContractorId: string;
  userId: string;
};

export const BizConstractorStore = {
  initData: { bizContractorId: "", userId: "" },
  IDMap: map<ContractorInfo>({ bizContractorId: "", userId: "" }),
  setContractorInfo: function (contractorInfo: ContractorInfo): void {
    this.IDMap.set(contractorInfo);
  },
  initContractorInfo: function (): void {
    this.IDMap.set(this.initData);
  },
} as const;

export const BizUserStore = {
  initData: {
    id: "",
    createdAt: undefined,
    updatedAt: undefined,
    createdUid: undefined,
    updatedUid: undefined,
    lastName: "",
    firstName: "",
    email: "",
  },
  bizUser: map<BizUser>({
    id: "",
    createdAt: undefined,
    updatedAt: undefined,
    createdUid: undefined,
    updatedUid: undefined,
    lastName: "",
    firstName: "",
    email: "",
  }),
  setBizUser: function (bizUser: BizUser): void {
    this.bizUser.set(bizUser);
  },
  initBizUser: function (): void {
    this.bizUser.set(this.initData);
  },
} as const;

export const OwnerIdTokenStore = {
  ownerIdToken: atom(""),
  set: function (idToken: string): void {
    this.ownerIdToken.set(idToken);
  },
  init: function (): void {
    this.ownerIdToken.set("");
  },
} as const;

export const initAllStore = (): void => {
  BizConstractorStore.initContractorInfo();
  BizUserStore.initBizUser();
  OwnerIdTokenStore.init();
};
