import { httpsCallable } from "firebase/functions";
import { ownerFunctions } from "lib/firebase";

interface RequestParams {
  companyId: string;
  projectId: string;
}

export async function updateBizEstimatesNotification(params: RequestParams): Promise<void> {
  const func = httpsCallable(ownerFunctions, "onCallUpdateBizEstimatesNotification");
  await func(params);
}
