import { Contract } from "@shared/utils";
import { useSnackbar } from "context/snackbarContext";
import { downloadFile, getContractURL } from "../../useCases/storage/contractFile";
import { Project } from "repositories/owner/Project";
import { Button } from "@mui/material";

interface DownloadInvoiceExcelProps {
  project: Project;
}

/**請求書エクセルダウンロード */
export const DownloadInvoiceExcel = ({ project }: DownloadInvoiceExcelProps): JSX.Element => {
  const { showSnackbar } = useSnackbar();

  const canDownload = Contract.canDownload(project.status).invoice;

  const handleDownloadInvoice = async (): Promise<void> => {
    const invoiceURL = await getContractURL("請求書", project.companyId, project.id!);

    if (!invoiceURL) {
      showSnackbar("請求書がダウンロードできませんでした。", "error");
      return;
    }

    downloadFile(invoiceURL, `${project.projectName}_請求書.xlsx`);
  };

  return (
    <Button
      variant="contained"
      className={`font-bold text-xs rounded-lg ${canDownload && "bg-remodela-green"}`}
      disabled={!canDownload}
      onClick={handleDownloadInvoice}
    >
      ダウンロード
    </Button>
  );
};
