import { ChangeEvent, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  IconButton,
  InputAdornment,
  MenuItem,
  Paper,
  Radio,
  RadioGroup,
  TextField,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

import Big, { RoundingMode } from "big.js";
import { ja } from "date-fns/locale";
import { Timestamp } from "firebase/firestore";
import { LocalizationProvider, DatePicker } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";

import { TaxType } from "@shared/types/entities/owner/Project";
import { useBackdrop } from "context/backdropContext";
import { useSnackbar } from "context/snackbarContext";
import {
  ValidityPeriodType,
  useCategorizedEstimatesContext,
} from "features/estimates/context/categorizedEstimatesContext";

/**合計小計 */
const TotalAmount = (): JSX.Element => {
  const { taxType, changeTaxType, getTotal, getSubTotal, getTax, existsOwnerFixed } = useCategorizedEstimatesContext();

  const handleChangeTaxType = (event: ChangeEvent<HTMLInputElement>) => {
    changeTaxType(event.target.value as TaxType);
  };

  return (
    <Box className="flex flex-col">
      <Box className="flex items-end">
        <div>合計</div>
        <div className="text-2xl text-pink font-bold pl-2">{`¥${getTotal().toLocaleString()}`}</div>
      </Box>
      <Box className="flex">
        <Box className="flex items-center">
          <div>小計</div>
          <div className="font-bold pl-2">{`¥${getSubTotal().toLocaleString()}`}</div>
        </Box>
        <Box className="flex items-center pl-2">
          <TextField
            select
            size="small"
            value={taxType}
            className="w-44"
            onChange={handleChangeTaxType}
            disabled={existsOwnerFixed()}
          >
            <MenuItem value={"roundDown"}>消費税10%切捨て</MenuItem>
            <MenuItem value={"roundUp"}>消費税10%切上げ</MenuItem>
            <MenuItem value={"zero"}>消費税0%</MenuItem>
          </TextField>
          <div className="font-bold pl-2">{`¥${getTax().toLocaleString()}`}</div>
        </Box>
      </Box>
    </Box>
  );
};

type DiscountDialogProps = {
  open: boolean;
  handleDialogClose: () => void;
  total: number;
  subTotal: number;
};
/**値引き設定ダイアログ */
const DiscountDialog = ({ open, handleDialogClose, total, subTotal }: DiscountDialogProps): JSX.Element => {
  // 値引き後合計
  const [discountedTotal, setDiscountedTotal] = useState<number>(total);
  const [discountAmount, setDiscountAmount] = useState<number>(0);
  const { taxType, editDiscountCategory } = useCategorizedEstimatesContext();

  // 値引き行追加
  const handleAddDiscountCategory = () => {
    editDiscountCategory(discountAmount);
    handleDialogClose();
  };

  // 値引額反映
  const handleInputChange = (value: string): void => {
    const discountedTotal = Number(value);
    if (isNaN(discountedTotal)) {
      return;
    }

    // 値引き後合計
    setDiscountedTotal(discountedTotal);

    if (taxType === "zero") {
      // 値引額 = 値引き後合計 - 値引き前小計
      const discountAmount = discountedTotal - subTotal;
      setDiscountAmount(discountAmount);
      return;
    }

    // 値引き後の小計に対して消費税の[切上げ/切捨て]が発生するので、
    // 値引き金額は消費税とは逆の[切上げ/切捨て]を行う必要がある
    let roundingMode: RoundingMode = 0;
    if (taxType === "roundDown") {
      roundingMode = Big.roundUp;
    } else if (taxType === "roundUp") {
      roundingMode = Big.roundDown;
    }

    const discountedSubTotal = Big(discountedTotal).div(1.1).round(0, roundingMode).toNumber();

    // 値引額 = 値引き後小計 - 値引き前小計
    const discountAmount = discountedSubTotal - subTotal;
    setDiscountAmount(discountAmount);
  };

  return (
    <Dialog open={open} maxWidth="xl" PaperComponent={Paper} PaperProps={{ className: "rounded-2xl" }}>
      <DialogTitle className="flex justify-between items-center">
        <div className="font-bold">値引き設定</div>
        <IconButton onClick={handleDialogClose}>
          <CloseIcon />
        </IconButton>
      </DialogTitle>

      <DialogContent className="w-96">
        <Box className="flex items-center h-10">
          <div className="w-28">値引き前合計</div>
          <div>{`¥${total.toLocaleString()}`}</div>
        </Box>
        <Box className="flex items-center h-10">
          <div className="w-28">値引き額</div>
          <div className="text-remodela-error">{`¥${discountAmount.toLocaleString()}`}</div>
        </Box>
        <Box className="flex items-center h-10">
          <div className="w-28">値引き後合計</div>
          <TextField
            value={discountedTotal || ""}
            onChange={(e) => handleInputChange(e.target.value)}
            type="number"
            variant="outlined"
            size="small"
            InputProps={{
              startAdornment: <InputAdornment position="start">¥</InputAdornment>,
            }}
            className="w-36"
          />
        </Box>
      </DialogContent>

      <DialogActions>
        <Button
          variant="outlined"
          className="font-bold bg-white text-darkBlueGray border-2 border-mediumGray w-28"
          onClick={handleDialogClose}
        >
          キャンセル
        </Button>
        <Button variant="contained" className="bg-remodela-green font-bold" onClick={handleAddDiscountCategory}>
          値引き行を追加
        </Button>
      </DialogActions>
    </Dialog>
  );
};

/**値引き設定 */
const DiscountSetting = (): JSX.Element => {
  const [discountDialogOpen, setDiscountDialogOpen] = useState<boolean>(false);
  const { getTotal, getSubTotal, existsOwnerFixed } = useCategorizedEstimatesContext();

  /**ダイアログ開閉 */
  const handleDiscountDialogOpen = (): void => setDiscountDialogOpen(true);
  const handleDiscountDialogClose = (): void => setDiscountDialogOpen(false);

  return (
    <>
      <Box className="mx-4">
        <Button
          variant="outlined"
          className="text-remodela-green border-remodela-green rounded-xl font-bold"
          onClick={handleDiscountDialogOpen}
          disabled={existsOwnerFixed()}
        >
          値引き設定
        </Button>
      </Box>
      {discountDialogOpen && (
        <DiscountDialog
          open={discountDialogOpen}
          handleDialogClose={handleDiscountDialogClose}
          total={getTotal()}
          subTotal={getSubTotal()}
        />
      )}
    </>
  );
};

// /**見積有効期限 */
const EstimateValidityPeriod = (): JSX.Element => {
  const { validityPeriod, validityPeriodChangeRadio, validityPeriodSetCalendar, validityPeriodSetDays } =
    useCategorizedEstimatesContext();

  const validityPeriodType = validityPeriod.hasOwnProperty("days") ? "days" : "calendar";

  /**ラジオボタン変更 */
  const handleChangeRadio = (value: ValidityPeriodType): void => {
    validityPeriodChangeRadio(value);
  };

  /**カレンダー日付変更 */
  const handleChangeDatePicker = (newValue: Date | null): void => {
    if (!newValue) {
      return;
    }

    const isInvalidDate = Number.isNaN(newValue.getTime());
    if (!isInvalidDate) {
      validityPeriodSetCalendar(Timestamp.fromDate(newValue));
    }
  };

  /**日数入力 */
  const handleChangeDays = (value: string): void => {
    const days = Number(value);
    if (isNaN(days)) {
      return;
    }

    validityPeriodSetDays(days);
  };

  return (
    <Box className="flex items-center">
      <Box className="flex items-center mx-4">
        <p>見積有効期限設定：</p>
        <RadioGroup
          row
          value={validityPeriodType}
          onChange={(e) => handleChangeRadio(e.target.value as ValidityPeriodType)}
        >
          <FormControlLabel
            value="calendar"
            control={<Radio className="text-remodela-green" />}
            label="日付選択"
            labelPlacement="start"
          />
          <FormControlLabel
            value="days"
            control={<Radio className="text-remodela-green" />}
            label="日数を入力"
            labelPlacement="start"
          />
        </RadioGroup>
      </Box>

      {validityPeriodType === "calendar" && (
        <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={ja}>
          <DatePicker
            value={validityPeriod.calendar?.toDate()}
            onChange={handleChangeDatePicker}
            slotProps={{
              textField: {
                size: "small",
                className: "w-44",
              },
            }}
          />
        </LocalizationProvider>
      )}
      {validityPeriodType === "days" && (
        <TextField
          value={validityPeriod.days || ""}
          size="small"
          className="w-44"
          type="number"
          InputProps={{
            endAdornment: <InputAdornment position="end">日</InputAdornment>,
          }}
          onChange={(e) => handleChangeDays(e.target.value)}
        />
      )}
    </Box>
  );
};

/**見積保存 */
const SaveButton = (): JSX.Element => {
  const { companyId, projectId } = useParams();
  const navigate = useNavigate();
  const { setBackdrop } = useBackdrop();
  const { showSnackbar } = useSnackbar();
  const { save, project } = useCategorizedEstimatesContext();

  const handleSaveProjectAndEstimates = async (): Promise<void> => {
    setBackdrop(true);

    try {
      await save();

      if (project.status === "工事中") {
        showSnackbar(`見積変更を${project.companyName}様に報告しました。`, "success");
      } else {
        showSnackbar("見積を登録しました。", "success");
      }

      navigate(`/companies/${companyId}/projects/${projectId}`);
    } catch (e) {
      showSnackbar("見積の登録に失敗しました。", "error");
    } finally {
      setBackdrop(false);
    }
  };

  return (
    <Button
      variant="contained"
      className="font-bold bg-remodela-green w-40 ml-4"
      onClick={handleSaveProjectAndEstimates}
    >
      保存する
    </Button>
  );
};

export const TotalAmountHeader = (): JSX.Element => {
  return (
    <>
      <Box className="sticky top-0 z-10 bg-white flex py-4 px-6 border-b-[1px] border-lightGray">
        <Box className="flex items-center">
          <TotalAmount />
          <DiscountSetting />
        </Box>

        <Box className="flex items-center ml-auto">
          <EstimateValidityPeriod />
          <SaveButton />
        </Box>
      </Box>
    </>
  );
};
