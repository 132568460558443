import { useNavigate, useParams } from "react-router-dom";
import { Box, IconButton } from "@mui/material";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";

import { CommentThread } from "components/CommentThread/CommentThread";
import { TotalAmountHeader } from "./TotalAmountHeader";
import { EstimatesArea } from "./EstimatesArea";
import { DiscountArea } from "./DiscountArea";
import { Project } from "@shared/types/entities/owner/Project";

type EstimateEditorPCProps = {
  hasEstimates: boolean;
  project: Project;
};

export const EstimateEditorPC = ({ hasEstimates, project }: EstimateEditorPCProps): JSX.Element => {
  const { companyId, projectId } = useParams();
  const navigate = useNavigate();

  return (
    <>
      <Box className="flex items-center px-6 py-4 border-b border-lightGray">
        <IconButton
          onClick={() => {
            navigate(`/companies/${companyId}/projects/${projectId}`);
          }}
        >
          <ArrowBackIosNewIcon />
        </IconButton>
        <p className="text-xl px-3 font-medium tracking-wide">{hasEstimates ? "見積編集" : "見積作成"}</p>
      </Box>

      <TotalAmountHeader />

      <Box className="m-6">
        <EstimatesArea />
        <DiscountArea />
      </Box>

      <CommentThread companyId={companyId!} projectId={projectId!} />
    </>
  );
};
