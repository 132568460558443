import { getDownloadURL, ref } from "firebase/storage";
import { ownerStorage } from "lib/firebase";
import { Contract } from "@shared/utils";

async function getStorageDownloadURL(dirPath: string): Promise<string> {
  const storageRef = ref(ownerStorage, dirPath);
  return await getDownloadURL(storageRef);
}

export async function getBlobURL(storageDownloadURL: string): Promise<string> {
  const response = await fetch(storageDownloadURL);
  const blob = await response.blob();
  return URL.createObjectURL(blob);
}

export function downloadFile(url: string, fileName: string): void {
  const link = document.createElement("a");
  link.href = url;
  link.download = fileName;
  link.click();
  link.remove();
}

export async function getContractURL(
  type: "発注書" | "見積書" | "請求書",
  companyId: string,
  projectId: string,
  orderFileName?: string
): Promise<string> {
  try {
    let dirPath = "";

    if (type === "発注書" && orderFileName) {
      dirPath = Contract.savePath.getOrderPath(companyId, projectId, orderFileName);
    } else if (type === "見積書") {
      dirPath = Contract.savePath.getEstimatePath(companyId, projectId);
    } else if (type === "請求書") {
      dirPath = Contract.savePath.getInvoicePath(companyId, projectId);
    }

    const storageDownloadURL = await getStorageDownloadURL(dirPath);
    return await getBlobURL(storageDownloadURL);
  } catch (e) {
    console.log(e);
    return "";
  }
}
