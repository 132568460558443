import {
  Box,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import ErrorIcon from "@mui/icons-material/Error";

import { CustomTableHeaderRow } from "./EstimatesArea/DnDTable";
import { OwnerCommentTooltip } from "./EstimatesArea/Popup/OwnerCommentTooltip";
import { useCategorizedEstimatesContext } from "features/estimates/context/categorizedEstimatesContext";

export const DiscountArea = (): JSX.Element => {
  const { discountEstimate, existsOwnerFixed, deleteDiscountCategory } =
    useCategorizedEstimatesContext();

  const getTableRowClassName = (): string => {
    if (
      discountEstimate?.ownerComments &&
      discountEstimate?.ownerComments.length > 0
    ) {
      return "bg-remodela-warmOrange bg-opacity-10 border-b-2 border-b-remodela-warmOrange";
    }

    return "";
  };

  if (!discountEstimate) {
    return <></>;
  }

  return (
    <>
      <Paper
        elevation={0}
        className="p-6 my-6 border-[1px] border-remodela-error"
      >
        <Box className="mb-4 flex justify-between items-center">
          <div className="flex items-center text-lg font-bold text-remodela-error">
            <ErrorIcon />
            <div className="ml-1">{discountEstimate.categoryName}</div>
          </div>

          {!existsOwnerFixed() && (
            <div>
              <IconButton
                onClick={() => {
                  deleteDiscountCategory();
                }}
              >
                <CloseIcon />
              </IconButton>
            </div>
          )}
        </Box>
        <Box className="w-full overflow-auto">
          <TableContainer component={Paper} className="min-w-[1144px]">
            <Table aria-label="customized table">
              <TableHead>
                <CustomTableHeaderRow />
              </TableHead>
              <TableBody>
                <TableRow className={getTableRowClassName()}>
                  <TableCell className="text-center text-sm h-16 py-1 px-1 w-[60px]"></TableCell>
                  <TableCell className="relative text-center text-sm h-16 py-1 px-1 w-[70px]"></TableCell>
                  <TableCell className="text-center text-sm h-16 py-1 px-1 w-[350px]">
                    {discountEstimate.itemName}
                  </TableCell>
                  <TableCell className="text-center text-sm h-16 py-1 px-1 w-[250px]">
                    <div className="flex justify-center">
                      {discountEstimate.quantity}
                      {discountEstimate.unit}
                    </div>
                  </TableCell>
                  <TableCell className="text-center text-sm h-16 py-1 px-1 w-[140px]">
                    {`¥${discountEstimate.unitPrice.toLocaleString()}`}
                  </TableCell>
                  <TableCell className="text-center text-sm h-16 py-1 px-1 w-[160px]">
                    {`¥${discountEstimate.amount.toLocaleString()}`}
                  </TableCell>
                  <TableCell className="text-center text-sm h-16 py-1 px-1 w-[354px]">
                    {discountEstimate.note}
                  </TableCell>
                  <TableCell className="text-center text-sm h-16 py-1 px-1 w-[100px]">
                    {discountEstimate.ownerComments &&
                      discountEstimate.ownerComments.length > 0 && (
                        <OwnerCommentTooltip
                          ownerComments={discountEstimate.ownerComments}
                        />
                      )}
                  </TableCell>
                  <TableCell className="text-center text-sm h-16 py-1 px-1 w-[100px]"></TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
        </Box>
      </Paper>
    </>
  );
};
