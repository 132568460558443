import { httpsCallable } from "firebase/functions";
import { bizFunctions } from "lib/firebase";
import { CreateAPIOwner } from "@shared/types/functionParams";

type RequestParams = CreateAPIOwner.RequestParams;
type ResponseParams = CreateAPIOwner.ResponseParams;

export async function createSpotWorkerApiOwnerId(
    requestParams: RequestParams
): Promise<CreateAPIOwner.ResponseParams> {
    const func = httpsCallable(bizFunctions, "onCallCreateSpotWorkerApiOwner");

    const result = await func(requestParams);
    return result.data as ResponseParams;
};
