import { Box, CircularProgress, Modal } from "@mui/material";
import { useStore } from "@nanostores/react";
import { CreateAPIOwner } from "@shared/types/functionParams";
import { MainLayout } from "components/Layout/MainLayout";
import { BizConstractorStore, BizUserStore } from "store/nanostores/contractorInfo";
import { createSpotWorkerApiOwnerId } from "../api/createSpotWorkerApiOwnerId";
import { useSnackbar } from "context/snackbarContext";
import { FirebaseError } from "firebase/app";
import TextSection from "../components/TextSection";
import PointSection from "../components/PointSection";
import { useState } from "react";
import backgroundImage from '../../../assets/images/background.jpg';

export const SpotWorker = (): JSX.Element => {
    const { showSnackbar } = useSnackbar();
    const bizUser = useStore(BizUserStore.bizUser);
    const { bizContractorId, userId } = useStore(BizConstractorStore.IDMap);
    const [loading, setLoading] = useState(false); // ローディング状態を管理する

    const handleOpenNewTab = async () => {
        setLoading(true); // ローディング開始
        if (bizUser.workerMatchingApiOwnerId) {
            openSpotWorkerInNewTab(bizUser.workerMatchingApiOwnerId);
            setLoading(false); // ローディング終了
            return;
        }

        try {
            const req: CreateAPIOwner.RequestParams = { bizContractorId, bizUserId: userId };
            const res = await createSpotWorkerApiOwnerId(req);
            openSpotWorkerInNewTab(res.workerMatchingApiOwnerId);
        } catch (e) {
            if (e instanceof FirebaseError) {
                showSnackbar(`${e.code}: ${e.message}`, "error");
            } else {
                showSnackbar("登録に失敗しました", "error");
            }
        } finally {
            setLoading(false); // 最終的にローディング終了
        }
    };

    const openSpotWorkerInNewTab = (apiOwnerId: string) => {
        const url = process.env.REACT_APP_SPOT_WORKER_BASE_URL + apiOwnerId;
        window.open(url, "_blank");
    };

    return (
        <MainLayout title="職人マッチング">
            <Box className="relative w-screen h-[calc(100vh-64px)] overflow-hidden">
                <Box
                    component="img"
                    src={backgroundImage}
                    alt="背景画像"
                    className="w-full h-full object-cover absolute top-0 left-0"
                />
                <Box className="absolute top-0 left-0 w-full h-full bg-[#10692A] opacity-30 pointer-events-none"></Box>
                <Box className="absolute top-0 sm:top-5 md:top-10 w-full flex flex-col items-center justify-center p-5">
                    <TextSection handleOpenNewTab={handleOpenNewTab} />
                    <PointSection />
                </Box>
            </Box>

            <Modal
                open={loading}
                sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    backdropFilter: 'blur(4px)', // 背景をぼかす
                }}
            >
                <CircularProgress
                    sx={{
                        color: '#10692A', // プログレスの色をカスタマイズ
                    }}
                />
            </Modal>
        </MainLayout>
    );
};
