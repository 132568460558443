import { useState } from "react";
import { Box, IconButton } from "@mui/material";
import SourceIcon from "@mui/icons-material/Source";
import { FileSwapDialog } from "./FileSwapDialog";
import { Project } from "repositories/owner/Project";

interface Props {
  project: Project;
}

export const FileSwap = ({ project }: Props): JSX.Element => {
  const [isFileSwapDialogOpen, setIsFileSwapDialogOpen] = useState<boolean>(false);

  return (
    <>
      <Box className="flex items-center">
        <IconButton color="inherit" onClick={() => setIsFileSwapDialogOpen(true)}>
          <SourceIcon className="text-[#FEDA58]" />
        </IconButton>
        <Box className="px-1 text-sm font-normal text-remodela-error">※見積書を添付しないでください</Box>
      </Box>

      <FileSwapDialog open={isFileSwapDialogOpen} onClose={() => setIsFileSwapDialogOpen(false)} project={project} />
    </>
  );
};
