import { ChangeEvent, useRef, useState } from "react";

import { Controller, useForm } from "react-hook-form";
import { useLocation, useNavigate } from "react-router-dom";
import {
  TextField,
  MenuItem,
  Button,
  Box,
  Stepper,
  Step,
  StepLabel,
  Paper,
  InputAdornment,
  IconButton,
  FormControlLabel,
  Checkbox,
} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import CircleIcon from "@mui/icons-material/Circle";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import { createUserWithEmailAndPassword, sendEmailVerification } from "firebase/auth";
import { bizAuth } from "lib/firebase";
import { FirebaseError } from "firebase/app";
import { useSnackbar } from "context/snackbarContext";
import { JOB_TYPES, type JobType, Prefs } from "constants/constants";
import { setBizAuthCustomClaims } from "../api/setBizAuthCustomClaims";
import { createBizContractorAndUser } from "../api/createBizContractorAndUser";
import { CreateBizContractorAndUser, SetBizAuthCustomClaims } from "@shared/types/functionParams";
import { useBackdrop } from "context/backdropContext";
import { PublicLayout } from "components/Layout/PublicLayout";

type FormData = {
  email: string;
  password: string;
  contractorName: string;
  tel: string;
  representativeLastName: string;
  representativeFirstName: string;
  prefecture: string;
  city: string;
  address: string;
  staffLastName: string;
  staffFirstName: string;
  constructionLicenseId: string;
  invoiceNumber: number;

  isFindingNewClients: boolean; // 新規取引先
  needFinalInspection: boolean; // 退去立会の可否
};

const steps = ["企業情報登録", "登録内容確認", "登録完了"];

type ItemNameProps = {
  itemName: string;
  required?: boolean;
  description?: string;
};
const ItemName = ({ itemName, required, description }: ItemNameProps): JSX.Element => {
  return (
    <>
      <Box className="flex items-center mb-2">
        {required ? (
          <div className="flex justify-center items-center font-bold rounded-md text-white bg-remodela-error text-xs w-8 h-6 mr-1">
            必須
          </div>
        ) : (
          <div className="flex justify-center items-center font-bold rounded-md text-white bg-mediumGray text-xs w-8 h-6 mr-1">
            任意
          </div>
        )}

        <div className="font-bold text-sm">{itemName}</div>
      </Box>
      {description && <div className="text-sm">{description}</div>}
    </>
  );
};

type DigitalStampPreview = {
  base64: string;
  fileName: string;
  contentType: string;
};

type PriceListPreview = {
  base64: string;
  fileName: string;
  contentType: string;
};

export const RegisterForm = (): JSX.Element => {
  const [activeStep, setActiveStep] = useState<number>(0);
  const [digitalStampPreview, setDigitalStampPreview] = useState<DigitalStampPreview>();
  const [priceListPreview, setPriceListPreview] = useState<PriceListPreview>();
  const [formData, setFormData] = useState<FormData>();
  const [showPassword, setShowPassword] = useState<boolean>(false);
  const [selectedJobTypes, setSelectedJobTypes] = useState<string[]>([]);
  const [isAgree, setIsAgree] = useState<boolean>(false);

  const fileInputRef = useRef<HTMLInputElement>(null);
  const priceListFileInputRef = useRef<HTMLInputElement>(null);

  const search = useLocation().search;

  const navigate = useNavigate();
  const { setBackdrop } = useBackdrop();
  const { showSnackbar } = useSnackbar();
  const {
    register,
    control,
    handleSubmit,
    formState: { errors },
  } = useForm<FormData>();

  const handleClickShowPassword = () => setShowPassword((show) => !show);

  const handleNavigate = {
    toLogin: (): void => {
      navigate("/login");
    },
    toTop: (): void => {
      navigate("/");
    },
  };

  /**電子印鑑関連 */
  const digitalStamp = {
    handleFileSelectButtonClick: (): void => {
      fileInputRef.current?.click();
    },
    handleDeleteDigitalStampFile: (): void => {
      setDigitalStampPreview(undefined);
    },
    handleFileChange: (event: ChangeEvent<HTMLInputElement>): void => {
      if (!event.target.files) return;

      const file = event.target.files[0];
      if (file) {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onloadend = () => {
          const imageUrl = reader.result as string;
          setDigitalStampPreview({
            base64: imageUrl,
            fileName: file.name,
            contentType: file.type,
          });
        };
      }
    },
  };

  /**料金表関連(PDF/画像) */
  const priceList = {
    handleFileSelectButtonClick: (): void => {
      priceListFileInputRef.current?.click();
    },
    handleDeleteFile: (): void => {
      setPriceListPreview(undefined);
    },
    handleFileChange: (event: ChangeEvent<HTMLInputElement>): void => {
      if (!event.target.files) return;

      const file = event.target.files[0];
      if (!file) {
        return;
      }

      const validTypes = ["application/pdf", "image/jpeg", "image/png"];
      if (!validTypes.includes(file.type)) {
        showSnackbar("不正なファイル形式です。", "error");
        return;
      }

      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onloadend = () => {
        const imageUrl = reader.result as string;
        setPriceListPreview({
          base64: imageUrl,
          fileName: file.name,
          contentType: file.type,
        });
      };
    },
  };

  /**職種選択 */
  const handleClickSelectJobType = (jobType: JobType): void => {
    if (selectedJobTypes.includes(jobType)) {
      const filteredJobTypes = selectedJobTypes.filter((selectedJobType) => selectedJobType !== jobType);
      setSelectedJobTypes(filteredJobTypes);
      return;
    }

    if (jobType === "内装工事全般") {
      setSelectedJobTypes([jobType]);
    } else {
      // [内装工事全般]以外が選ばれた場合は[内装工事全般]を除く
      const filteredJobTypes = selectedJobTypes.filter((selectedJobType) => selectedJobType !== "内装工事全般");
      setSelectedJobTypes([...filteredJobTypes, jobType]);
    }
  };

  /**ステップ関連 */
  const step = {
    isStep1: activeStep === 0,
    isStep2: activeStep === 1,
    isStep3: activeStep === 2,
    handleBack: (): void => {
      setActiveStep((prevActiveStep) => prevActiveStep - 1);
      window.scrollTo(0, 0);
    },
    handleNext: (): void => {
      setActiveStep((prevActiveStep) => prevActiveStep + 1);
      window.scrollTo(0, 0);
    },
  };

  /**step1 → step2 (実行前にバリデーションが実行される)*/
  const onSubmit = async (data: FormData): Promise<void> => {
    setFormData(data);
    step.handleNext();
  };

  /**step2 → step3 */
  const saveFormData = async (): Promise<void> => {
    if (!formData) return;

    try {
      setBackdrop(true);

      const createBizContractorAndUserRequestParams: CreateBizContractorAndUser.RequestParams = {
        /**担当者情報 */
        staffLastName: formData.staffLastName,
        staffFirstName: formData.staffFirstName,
        email: formData.email,

        /**企業情報(必須) */
        contractorName: formData.contractorName,
        representativeLastName: formData.representativeLastName,
        representativeFirstName: formData.representativeFirstName,
        prefecture: formData.prefecture,
        city: formData.city,
        address: formData.address,
        tel: formData.tel,
        isFindingNewClients: formData.isFindingNewClients,
        jobTypes: selectedJobTypes,
        needFinalInspection: formData.needFinalInspection,

        /**企業情報(任意) */
        priceListPreview: priceListPreview,
        constructionLicenseId: formData.constructionLicenseId,
        invoiceId: formData.invoiceNumber ? `T${formData.invoiceNumber}` : "",
        digitalStampPreview: digitalStampPreview,

        /**原状回復から招待されている場合 */
        encryptedCompanyId: new URLSearchParams(search).get("companyId") || "",
      };
      const { bizContractorId, userId } = await createBizContractorAndUser(createBizContractorAndUserRequestParams);

      const { user } = await createUserWithEmailAndPassword(bizAuth, formData.email, formData.password);
      await sendEmailVerification(user);

      const setBizAuthCustomClaimsRequestParams: SetBizAuthCustomClaims.RequestParams = {
        uid: user.uid, // biz/Auth/ユーザー
        userId, // owner/Firestore/userId
        bizContractorId,
      };
      await setBizAuthCustomClaims(setBizAuthCustomClaimsRequestParams);

      step.handleNext();
    } catch (e) {
      let errorMessage = "登録に失敗しました。";

      if (e instanceof FirebaseError) {
        if (e.code === "auth/email-already-in-use") {
          errorMessage = "このメールアドレスは使用されています";
        } else {
          errorMessage = e.message;
        }
      }

      showSnackbar(errorMessage, "error");
      return;
    } finally {
      setBackdrop(false);
    }
  };

  return (
    <PublicLayout title="新規会員登録">
      <Box className="w-11/12 sm:w-2/3 m-auto pt-10">
        <Box className="flex justify-center w-2/3 m-auto">
          <div className="w-[200px] h-8 relative cursor-pointer" onClick={handleNavigate.toTop}>
            <img
              src={`${process.env.PUBLIC_URL}/images/remodelaBiz.png`}
              alt="リモデラBiz"
              className="w-full h-full object-cover absolute top-0 left-0"
            />
          </div>
        </Box>

        <Box className="w-2/3 m-auto">
          <div className="text-2xl font-bold text-center py-8">新規会員登録</div>
          <Stepper activeStep={activeStep} alternativeLabel className="mb-8">
            {steps.map((label) => {
              return (
                <Step key={label}>
                  <StepLabel
                    StepIconProps={{
                      className: "text-remodela-green",
                    }}
                  >
                    {label}
                  </StepLabel>
                </Step>
              );
            })}
          </Stepper>
        </Box>

        <Box className="pb-10">
          <form onSubmit={handleSubmit(onSubmit)}>
            {/* Step1 */}
            <Box className={!step.isStep1 ? "hidden" : ""}>
              <p className="text-sm">以下のフォームに会員登録に必要な事項を入力してください。</p>
              <Box>
                <div className="flex py-5 items-center">
                  <CircleIcon fontSize="medium" className="text-remodela-green" />
                  <div className="font-bold text-xl">パスワード設定</div>
                </div>

                <Paper elevation={0} className="w-full border-[1px] border-remodela-green py-8 px-2 sm:px-28">
                  <Box className="mb-4">
                    <ItemName required itemName="担当者名" />
                    <div className="flex">
                      <TextField
                        size="small"
                        fullWidth
                        placeholder="田中"
                        inputProps={register("staffLastName", {
                          required: "必須入力です",
                        })}
                        error={!!errors.staffLastName}
                        helperText={errors.staffLastName?.message}
                      />
                      <TextField
                        size="small"
                        fullWidth
                        placeholder="次郎"
                        inputProps={register("staffFirstName", {
                          required: "必須入力です",
                        })}
                        error={!!errors.staffFirstName}
                        helperText={errors.staffFirstName?.message}
                      />
                    </div>
                  </Box>
                  <Box className="mb-4">
                    <ItemName required itemName="メールアドレス" />
                    <TextField
                      type="mail"
                      placeholder="test@remodela.jp"
                      size="small"
                      fullWidth
                      inputProps={register("email", {
                        pattern: /^[a-zA-Z0-9_.+-]+@([a-zA-Z0-9][a-zA-Z0-9-]*[a-zA-Z0-9]*\.)+[a-zA-Z]{2,}$/,
                        required: "必須入力です",
                      })}
                      error={!!errors.email}
                      helperText={(() => {
                        if (errors.email?.type === "required") {
                          return errors.email?.message;
                        }
                        if (errors.email?.type === "pattern") {
                          return "メールアドレスを入力してください";
                        }
                        return "";
                      })()}
                    />
                  </Box>
                  <Box className="mb-4">
                    <ItemName
                      required
                      itemName="パスワード"
                      description="パスワードは6文字以上、半角英数字で入力してください。"
                    />
                    <TextField
                      size="small"
                      fullWidth
                      type={showPassword ? "text" : "password"}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton
                              aria-label="toggle password visibility"
                              onClick={handleClickShowPassword}
                              edge="end"
                            >
                              {showPassword ? <VisibilityOff /> : <Visibility />}
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                      inputProps={register("password", {
                        pattern: /^[0-9a-zA-Z]*$/,
                        minLength: 6,
                        required: "必須入力です",
                      })}
                      error={!!errors.password}
                      helperText={(() => {
                        if (errors.password?.type === "required") {
                          return errors.password?.message;
                        }
                        if (errors.password?.type === "minLength") {
                          return "6桁以上で設定してください";
                        }
                        if (errors.password?.type === "pattern") {
                          return "半角英数字を入力してください";
                        }
                        return "";
                      })()}
                    />
                  </Box>
                </Paper>
              </Box>

              <Box>
                <div className="flex py-5 items-center">
                  <CircleIcon fontSize="medium" className="text-remodela-green" />
                  <div className="font-bold text-xl">企業情報登録</div>
                </div>

                <Paper elevation={0} className="w-full border-[1px] border-remodela-green py-8 px-2 sm:px-28">
                  <Box className="mb-4">
                    <ItemName required itemName="企業名（屋号）" description="企業名または屋号をご入力ください。" />
                    <TextField
                      size="small"
                      fullWidth
                      placeholder="リモデラ工務店"
                      inputProps={register("contractorName", {
                        required: "必須入力です",
                      })}
                      error={!!errors.contractorName}
                      helperText={errors.contractorName?.message}
                      className="pl-4"
                    />
                  </Box>
                  <Box className="mb-4">
                    <ItemName required itemName="代表者名" />
                    <div className="flex pl-4">
                      <TextField
                        size="small"
                        fullWidth
                        placeholder="田中"
                        {...register("representativeLastName", {
                          required: "必須入力です",
                        })}
                        error={!!errors.representativeLastName}
                        helperText={errors.representativeLastName?.message}
                      />
                      <TextField
                        size="small"
                        fullWidth
                        placeholder="太郎"
                        {...register("representativeFirstName", {
                          required: "必須入力です",
                        })}
                        error={!!errors.representativeFirstName}
                        helperText={errors.representativeFirstName?.message}
                      />
                    </div>
                  </Box>
                  <Box className="mb-4">
                    <ItemName required itemName="会社所在地" />
                    <Box className="mb-4 pl-4">
                      <div className="font-bold text-sm">都道府県</div>
                      <TextField
                        size="small"
                        select
                        defaultValue=""
                        className="w-1/2"
                        inputProps={register("prefecture", {
                          required: "必須入力です",
                        })}
                        error={!!errors.prefecture}
                        helperText={errors.prefecture?.message}
                      >
                        {Prefs.PREFECTURES.map((prefecture) => (
                          <MenuItem key={prefecture} value={prefecture}>
                            {prefecture}
                          </MenuItem>
                        ))}
                      </TextField>
                    </Box>

                    <Box className="mb-4 pl-4">
                      <div className="font-bold text-sm">市区町村</div>
                      <TextField
                        size="small"
                        fullWidth
                        placeholder="千代田区"
                        inputProps={register("city", {
                          required: "必須入力です",
                        })}
                        error={!!errors.city}
                        helperText={errors.city?.message}
                      />
                    </Box>

                    <Box className="mb-4 pl-4">
                      <div className="font-bold text-sm">番地・建物名</div>
                      <TextField
                        size="small"
                        fullWidth
                        placeholder="丸の内1-1 リモデラビル2F"
                        inputProps={register("address", {
                          required: "必須入力です",
                        })}
                        error={!!errors.address}
                        helperText={errors.address?.message}
                      />
                    </Box>
                  </Box>
                  <Box className="mb-4">
                    <ItemName required itemName="電話番号" description="ハイフン無しで入力してください。" />
                    <TextField
                      size="small"
                      placeholder="0667664210"
                      className="w-1/2 pl-4"
                      inputProps={register("tel", {
                        pattern: /^0\d{9,10}$/,
                        required: "必須入力です",
                      })}
                      error={!!errors.tel}
                      helperText={(() => {
                        if (errors.tel?.type === "required") {
                          return errors.tel?.message;
                        }
                        if (errors.tel?.type === "pattern") {
                          return "電話番号を入力してください";
                        }
                        return "";
                      })()}
                    />
                  </Box>
                  <Box className="mb-4">
                    <ItemName required itemName="職種" />
                    <Box className="flex flex-wrap text-xs">
                      {JOB_TYPES.map((jobType) => {
                        return (
                          <div
                            key={jobType}
                            className={`px-2 py-1 mx-2 my-1 rounded-lg cursor-pointer border-[1px] border-remodela-green ${
                              selectedJobTypes.includes(jobType)
                                ? "bg-remodela-green text-white"
                                : "text-remodela-green"
                            }`}
                            onClick={() => handleClickSelectJobType(jobType)}
                          >
                            {jobType}
                          </div>
                        );
                      })}
                    </Box>
                  </Box>
                  <Box className="mb-4">
                    <ItemName required itemName="新規取引先" />
                    <Controller
                      name="isFindingNewClients"
                      control={control}
                      defaultValue={false}
                      render={({ field }) => (
                        <FormControlLabel
                          className="pl-4"
                          control={
                            <Checkbox
                              size="small"
                              className="text-remodela-green"
                              checked={field.value}
                              onChange={(e) => field.onChange(e.target.checked)}
                            />
                          }
                          label="新規取引先を探したい"
                          sx={{
                            "& .MuiFormControlLabel-label": {
                              fontSize: "14px",
                            },
                          }}
                        />
                      )}
                    />
                    <p className="pl-4 text-sm text-pink">
                      ※チェックを入れると、不動産会社から取引開始のオファーが届くようになります
                    </p>
                  </Box>
                  <Box className="mb-4">
                    <ItemName required itemName="退去立会" />
                    <Controller
                      name="needFinalInspection"
                      control={control}
                      defaultValue={false}
                      render={({ field }) => (
                        <FormControlLabel
                          className="pl-4"
                          control={
                            <Checkbox
                              size="small"
                              className="text-remodela-green"
                              checked={field.value}
                              onChange={(e) => field.onChange(e.target.checked)}
                            />
                          }
                          label="退去立会も対応できる"
                          sx={{
                            "& .MuiFormControlLabel-label": {
                              fontSize: "14px",
                            },
                          }}
                        />
                      )}
                    />
                  </Box>
                  <Box className="mb-4">
                    <ItemName itemName="料金表" />
                    <Box className="pl-4">
                      <input
                        type="file"
                        accept="application/pdf,image/*"
                        ref={priceListFileInputRef}
                        onChange={priceList.handleFileChange}
                        className="hidden"
                      />

                      <Box className="flex items-center">
                        {priceListPreview && (
                          <Box className="relative h-60 w-60 mr-3">
                            {priceListPreview.contentType.includes("image") ? (
                              <img
                                src={priceListPreview.base64}
                                alt="プレビュー"
                                className="h-full w-full object-contain"
                              />
                            ) : (
                              <embed
                                type="application/pdf"
                                src={priceListPreview.base64}
                                className="h-full w-full object-contain"
                              />
                            )}

                            <IconButton
                              className="absolute top-[-8px] right-[-8px] bg-mediumGray text-white w-5 h-5"
                              onClick={priceList.handleDeleteFile}
                            >
                              <DeleteIcon className="w-4 h-4" />
                            </IconButton>
                          </Box>
                        )}
                        <Button
                          variant="outlined"
                          className="text-remodela-green border-remodela-green rounded-xl font-bold h-8"
                          onClick={priceList.handleFileSelectButtonClick}
                        >
                          ファイルを選択
                        </Button>
                      </Box>
                    </Box>
                  </Box>

                  <Box className="mb-4">
                    <ItemName itemName="建設業許可番号" />
                    <TextField size="small" className="w-1/2 pl-4" inputProps={register("constructionLicenseId")} />
                  </Box>
                  <Box className="mb-4">
                    <ItemName itemName="登録番号" description="インボイス制度の適格請求書登録番号をご入力ください。" />
                    <TextField
                      size="small"
                      placeholder="1234567890123"
                      className="w-1/2 pl-4"
                      InputProps={{
                        startAdornment: <InputAdornment position="start">T</InputAdornment>,
                      }}
                      inputProps={register("invoiceNumber", {
                        pattern: /[0-9]/,
                        maxLength: 13,
                        minLength: 13,
                      })}
                      error={!!errors.invoiceNumber}
                      helperText={(() => {
                        if (errors.invoiceNumber?.type === "maxLength" || errors.invoiceNumber?.type === "minLength") {
                          return "13桁を入力してください";
                        }
                        if (errors.invoiceNumber?.type === "pattern") {
                          return "半角数字を入力してください";
                        }
                        return "";
                      })()}
                    />
                  </Box>
                  <Box className="mb-4">
                    <ItemName itemName="電子印鑑" />
                    <Box className="pl-4">
                      <input
                        type="file"
                        accept="image/*"
                        ref={fileInputRef}
                        onChange={digitalStamp.handleFileChange}
                        className="hidden"
                      />

                      <Box className="flex items-center">
                        {digitalStampPreview && (
                          <Box className="relative h-20 w-20 mr-3">
                            <img
                              src={digitalStampPreview.base64}
                              alt="プレビュー"
                              className="h-full w-full object-contain"
                            />
                            <IconButton
                              className="absolute top-[-8px] right-[-8px] bg-mediumGray text-white w-5 h-5"
                              onClick={digitalStamp.handleDeleteDigitalStampFile}
                            >
                              <DeleteIcon className="w-4 h-4" />
                            </IconButton>
                          </Box>
                        )}
                        <Button
                          variant="outlined"
                          className="text-remodela-green border-remodela-green rounded-xl font-bold h-8"
                          onClick={digitalStamp.handleFileSelectButtonClick}
                        >
                          ファイルを選択
                        </Button>
                      </Box>
                    </Box>
                  </Box>
                </Paper>
              </Box>

              <Box className="flex justify-center mt-10">
                <Button
                  type="submit"
                  variant="contained"
                  className="bg-remodela-green font-bold text-xs rounded-lg w-40 h-12"
                >
                  次に進む
                </Button>
              </Box>
            </Box>

            {/* Step2 */}
            <Box className={!step.isStep2 ? "hidden" : ""}>
              <p className="text-sm">
                ご入力いただいた登録内容を確認していただき、問題がなければ
                <b>登録</b>をクリックしてください。
              </p>
              <Box>
                <div className="flex py-5 items-center">
                  <CircleIcon fontSize="medium" className="text-remodela-green" />
                  <div className="font-bold text-xl">パスワード設定</div>
                </div>

                <Paper elevation={0} className="w-full border-[1px] border-remodela-green py-8 px-2 sm:px-28">
                  <Box className="mb-4">
                    <div className="font-bold text-sm">担当者名</div>
                    <div className="text-sm">{`${formData?.staffLastName} ${formData?.staffFirstName}`}</div>
                  </Box>
                  <Box className="mb-4">
                    <div className="font-bold text-sm">メールアドレス</div>
                    <div className="text-sm">{formData?.email}</div>
                  </Box>
                  <Box className="mb-4">
                    <div className="font-bold text-sm">パスワード</div>
                    <div className="text-sm">{"*".repeat(formData?.password?.length || 1)}</div>
                  </Box>
                </Paper>
              </Box>

              <Box>
                <div className="flex py-5 items-center">
                  <CircleIcon fontSize="medium" className="text-remodela-green" />
                  <div className="font-bold text-xl">企業情報登録</div>
                </div>

                <Paper elevation={0} className="w-full border-[1px] border-remodela-green py-8 px-2 sm:px-28">
                  <Box className="mb-4">
                    <div className="font-bold text-sm">企業名（屋号）</div>
                    <div className="text-sm">{formData?.contractorName}</div>
                  </Box>
                  <Box className="mb-4">
                    <div className="font-bold text-sm">代表者名</div>
                    <div className="text-sm">
                      {`${formData?.representativeLastName} ${formData?.representativeFirstName}`}
                    </div>
                  </Box>
                  <Box className="mb-4">
                    <div className="font-bold text-sm">会社所在地</div>
                    <div className="text-sm">{`${formData?.prefecture} ${formData?.city} ${formData?.address}`}</div>
                  </Box>
                  <Box className="mb-4">
                    <div className="font-bold text-sm">電話番号</div>
                    <div className="text-sm">{formData?.tel}</div>
                  </Box>
                  <Box className="mb-4">
                    <div className="font-bold text-sm">職種</div>
                    <div className="text-sm">{selectedJobTypes.join(" / ")}</div>
                  </Box>
                  <Box className="mb-4">
                    <div className="font-bold text-sm">新規取引先</div>
                    <div className="text-sm">
                      {formData?.isFindingNewClients ? "新規取引先を探したい" : "新規取引先を探さない"}
                    </div>
                  </Box>
                  <Box className="mb-4">
                    <div className="font-bold text-sm">退去立会</div>
                    <div className="text-sm">
                      {formData?.needFinalInspection ? "退去立会も対応できる" : "退去立会は対応できない"}
                    </div>
                  </Box>
                  <Box className="mb-4">
                    <div className="font-bold text-sm">料金表</div>
                    {priceListPreview && (
                      <Box className="relative h-60 w-60 mr-3">
                        {priceListPreview.contentType.includes("image") ? (
                          <img
                            src={priceListPreview.base64}
                            alt="プレビュー"
                            className="h-full w-full object-contain"
                          />
                        ) : (
                          <embed
                            type="application/pdf"
                            src={priceListPreview.base64}
                            className="h-full w-full object-contain"
                          />
                        )}
                      </Box>
                    )}
                  </Box>
                  <Box className="mb-4">
                    <div className="font-bold text-sm">建設業許可番号</div>
                    <div className="text-sm">{formData?.constructionLicenseId || "-"}</div>
                  </Box>
                  <Box className="mb-4">
                    <div className="font-bold text-sm">登録番号</div>
                    <div className="text-sm">{formData?.invoiceNumber ? `T${formData?.invoiceNumber}` : "-"}</div>
                  </Box>
                  <Box className="mb-4">
                    <div className="font-bold text-sm">電子印鑑</div>
                    {digitalStampPreview && (
                      <Box className="relative h-20 w-20 mr-3">
                        <img
                          src={digitalStampPreview.base64}
                          alt="プレビュー"
                          className="h-full w-full object-contain"
                        />
                      </Box>
                    )}
                  </Box>
                </Paper>
              </Box>

              <Box>
                <Checkbox
                  size="small"
                  className="text-remodela-green"
                  onChange={() => {
                    setIsAgree((current) => !current);
                  }}
                />
                <span>
                  <a
                    href="https://www.remodela.jp/tos"
                    target="_blank"
                    rel="noreferrer"
                    className="underline underline-offset-2"
                  >
                    利用規約
                  </a>
                  <span className="mx-2">および</span>
                  <a
                    href="https://about.remodela.jp/privacy.html"
                    target="_blank"
                    rel="noreferrer"
                    className="underline underline-offset-2"
                  >
                    個人情報保護方針
                  </a>
                  <span className="mx-2">に同意する</span>
                </span>
              </Box>

              <Box className="flex justify-center mt-10">
                <Button
                  variant="outlined"
                  className="font-bold bg-white text-darkBlueGray rounded-lg border-2 border-mediumGray w-40 h-12 mx-3"
                  onClick={step.handleBack}
                >
                  前の画面に戻る
                </Button>
                <Button
                  variant="contained"
                  className="bg-remodela-green font-bold text-xs rounded-lg w-40 h-12 mx-3"
                  onClick={saveFormData}
                  disabled={!isAgree}
                >
                  登録
                </Button>
              </Box>
            </Box>

            {/* Step3 */}
            <Box className={!step.isStep3 ? "hidden" : ""}>
              <Box className="flex flex-col items-center sm:w-1/2 m-auto">
                <CheckCircleOutlineIcon className="text-remodela-green text-7xl" />
                <div className="font-bold text-2xl tracking-widest py-3">会員登録完了</div>
                <div className="text-sm">
                  会員登録していただきありがとうございます。ご本人様確認のためメールアドレス認証を実施しています｡お手数ではありますが登録時に記載いただいたメールアドレスをご確認ください。
                </div>
                <Button
                  variant="contained"
                  className="bg-remodela-green font-bold text-xs rounded-lg w-40 h-12 my-3"
                  onClick={handleNavigate.toLogin}
                >
                  ログイン画面へ
                </Button>
              </Box>
            </Box>
          </form>
        </Box>
      </Box>
    </PublicLayout>
  );
};
