import { httpsCallable } from "firebase/functions";
import { ownerFunctions } from "lib/firebase";

interface RequestParams {
  bizContractorId: string;
  comment: string;
  companyId: string;
  pic: string;
  projectId: string;
  projectName: string;
  type: "contractor";
}

// TODO: 移動させたい
export async function addBizEstimateComment(requestParams: RequestParams): Promise<void> {
  const func = httpsCallable(ownerFunctions, "onCallAddBizEstimateComment");
  await func(requestParams);
}
