import { Autocomplete, Box, IconButton, Paper, TextField } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import AddIcon from "@mui/icons-material/Add";
import { DnDTable } from "./DnDTable";
import { JOB_TYPES } from "constants/constants";
import {
  CategorizedEstimate,
  useCategorizedEstimatesContext,
} from "features/estimates/context/categorizedEstimatesContext";

type Props = { categorizedEstimate: CategorizedEstimate };
/**新しいカテゴリー*/
const NewCategory = ({ categorizedEstimate }: Props): JSX.Element => {
  const { deleteCategory, setCategoryName, existsOwnerFixedByCategoryId } =
    useCategorizedEstimatesContext();
  const categoryId = categorizedEstimate.categoryId;

  return (
    <Box className="mb-4 flex justify-between">
      <Box>
        <p className="font-bold">新しいカテゴリー</p>
        <Autocomplete
          value={categorizedEstimate.categoryName || ""}
          size="small"
          freeSolo
          onChange={(_, newValue) => {
            setCategoryName(newValue || "", categoryId);
          }}
          options={JOB_TYPES}
          renderInput={(params) => (
            <TextField
              placeholder="クロス張替工事"
              onChange={(e) => setCategoryName(e.target.value, categoryId)}
              {...params}
            />
          )}
          className="w-72"
        />
      </Box>

      {!existsOwnerFixedByCategoryId(categoryId) && (
        <Box>
          <IconButton
            onClick={() => {
              deleteCategory(categoryId);
            }}
          >
            <CloseIcon />
          </IconButton>
        </Box>
      )}
    </Box>
  );
};

/**内訳*/
const EstimateBreakdown = ({ categorizedEstimate }: Props): JSX.Element => {
  return (
    <Box className="w-full overflow-auto">
      <p className="font-bold">内訳</p>
      <DnDTable categorizedEstimate={categorizedEstimate} />
    </Box>
  );
};

/**行追加*/
const AddNewRow = ({ categoryId }: { categoryId: string }): JSX.Element => {
  const { addRow, existsOwnerFixedByCategoryId } =
    useCategorizedEstimatesContext();

  // 一つでも施主fixしていたら表示させない
  if (existsOwnerFixedByCategoryId(categoryId)) {
    return <></>;
  }

  return (
    <Box className="flex justify-end">
      <Box
        className="flex items-center cursor-pointer"
        onClick={() => addRow(categoryId)}
      >
        <AddIcon className="text-3xl" />
        <p className="font-bold text-sm">新しい行を追加する</p>
      </Box>
    </Box>
  );
};

type EstimatesByCategoryProps = {
  categorizedEstimate: CategorizedEstimate;
};
export const EstimatesByCategory = ({
  categorizedEstimate,
}: EstimatesByCategoryProps) => {
  if (categorizedEstimate.fixed) {
    return (
      <Paper className="p-6 mb-4 pointer-events-none relative">
        <Box
          sx={{
            position: "absolute",
            top: 0,
            left: 0,
            bottom: 0,
            right: 0,
            backgroundColor: "black",
            opacity: 0.4,
            zIndex: 1,
          }}
        />
        <NewCategory categorizedEstimate={categorizedEstimate} />
        <EstimateBreakdown categorizedEstimate={categorizedEstimate} />
        <AddNewRow categoryId={categorizedEstimate.categoryId} />
      </Paper>
    );
  }

  return (
    <>
      <Paper className="p-6 mb-4">
        <NewCategory categorizedEstimate={categorizedEstimate} />
        <EstimateBreakdown categorizedEstimate={categorizedEstimate} />
        <AddNewRow categoryId={categorizedEstimate.categoryId} />
      </Paper>
    </>
  );
};
