import { useState } from "react";
import { ClickAwayListener, IconButton, Tooltip, Box } from "@mui/material";
import ChatOutlinedIcon from "@mui/icons-material/ChatOutlined";
import CircleOutlinedIcon from "@mui/icons-material/CircleOutlined";
import { OwnerComment } from "@shared/types/entities/owner/BizEstimate";
import { getFormattedDateTime } from "utils";

type OwnerCommentTooltipProps = { ownerComments: OwnerComment[] };
export const OwnerCommentTooltip = ({
  ownerComments,
}: OwnerCommentTooltipProps): JSX.Element => {
  const [tooltipOpen, setTooltipOpen] = useState<boolean>(false);

  const handleTooltipClose = (): void => {
    setTooltipOpen(false);
  };
  const handleTooltipOpen = (): void => {
    setTooltipOpen(true);
  };

  return (
    <ClickAwayListener onClickAway={handleTooltipClose}>
      <Tooltip
        title={ownerComments.map((ownerComment, index) => {
          return (
            <Box key={index} className="text-darkBlueGray py-2">
              <div className="flex items-center pb-2">
                <CircleOutlinedIcon
                  fontSize="small"
                  className="text-lightGray"
                />
                <div className="font-bold text-sm">{ownerComment.pic}</div>
              </div>
              <div className="text-sm pb-2">{ownerComment.comment}</div>
              <div className="font-bold text-xs text-mediumGray">
                {getFormattedDateTime(ownerComment.createdAt)}
              </div>
            </Box>
          );
        })}
        open={tooltipOpen}
        componentsProps={{
          tooltip: {
            sx: {
              backgroundColor: "white",
              boxShadow: 10,
            },
          },
        }}
      >
        <IconButton
          size="small"
          className="text-remodela-warmOrange"
          onClick={handleTooltipOpen}
        >
          <ChatOutlinedIcon />
        </IconButton>
      </Tooltip>
    </ClickAwayListener>
  );
};
