import { Box, IconButton, Paper } from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";

import { useCategorizedEstimatesContext } from "features/estimates/context/categorizedEstimatesContext";

export const EstimateByDiscount = (): JSX.Element => {
  const { discountEstimate, deleteDiscountCategory, existsOwnerFixed } =
    useCategorizedEstimatesContext();

  if (!discountEstimate) {
    return <></>;
  }

  return (
    <>
      <Paper elevation={12} className="mb-4 border-b-remodela-error border-b-4">
        <Box className=" flex justify-between bg-lightPink">
          <Box className="w-full p-2 font-bold text-remodela-error">
            お値引き
          </Box>

          {!existsOwnerFixed() && (
            <div>
              <IconButton onClick={deleteDiscountCategory}>
                <DeleteIcon className="text-remodela-error" />
              </IconButton>
            </div>
          )}
        </Box>

        <Box className="p-2">
          {`1式 ¥${discountEstimate?.amount.toLocaleString()}`}
        </Box>
      </Paper>
    </>
  );
};
