import { useEffect, useState } from "react";
import { Box, Button, Dialog } from "@mui/material";
import { Contract } from "@shared/utils";
import { useSnackbar } from "context/snackbarContext";
import { downloadFile, getContractURL } from "../../useCases/storage/contractFile";
import { Project } from "repositories/owner/Project";
import { listAll, ref } from "firebase/storage";
import { ownerStorage } from "lib/firebase";

interface DownloadOrderPDFProps {
  project: Project;
}

/**発注書PDFダウンロード */
export const DownloadOrderPDF = ({ project }: DownloadOrderPDFProps): JSX.Element => {
  const [isDialogOpen, setIsDialogOpen] = useState<boolean>(false);
  const canDownload = Contract.canDownload(project.status).order;

  return (
    <>
      <Button
        variant="contained"
        className={`font-bold text-xs rounded-lg ${canDownload && "bg-remodela-green"}`}
        disabled={!canDownload}
        onClick={() => setIsDialogOpen(true)}
      >
        ダウンロード
      </Button>

      <OrderPDFDownloadDialog open={isDialogOpen} handleClose={() => setIsDialogOpen(false)} project={project} />
    </>
  );
};

interface OrderPDFDownloadDialogProps {
  open: boolean;
  handleClose: () => void;
  project: Project;
}
/**発注書の選択ダイアログ */
const OrderPDFDownloadDialog = ({ open, handleClose, project }: OrderPDFDownloadDialogProps): JSX.Element => {
  const [fileNames, setFileNames] = useState<string[]>();
  const { showSnackbar } = useSnackbar();

  const handleDownloadOrder = async (fileName: string): Promise<void> => {
    const orderURL = await getContractURL("発注書", project.companyId, project.id!, fileName);

    if (!orderURL) {
      showSnackbar("発注書がダウンロードできませんでした。", "error");
      return;
    }

    downloadFile(orderURL, `${project.projectName}_${fileName}`);
  };

  useEffect(() => {
    const fetchOrderPDFFileNames = async (): Promise<void> => {
      const storageRef = ref(ownerStorage, `biz/companies/${project.companyId}/projects/${project.id!}/contract`);

      const listResult = await listAll(storageRef);

      const fileNames = listResult.items
        .map((item) => item.name)
        .filter((fileName) => fileName.includes("発注書・発注請書"));

      setFileNames(fileNames);
    };

    fetchOrderPDFFileNames();
  }, [project]);

  if (!fileNames) {
    return <></>;
  }

  return (
    <Dialog onClose={handleClose} open={open}>
      <Box className="flex flex-col items-center p-3 space-y-4">
        {fileNames.length === 0 && <Box>発注書がありません。</Box>}

        {fileNames.map((fileName, index) => {
          return (
            <Button
              key={index}
              variant="outlined"
              className="text-remodela-green border-remodela-green  font-bold text-xs rounded-lg h-8 mx-3"
              onClick={() => {
                handleDownloadOrder(fileName);
              }}
            >
              {fileName}
            </Button>
          );
        })}
      </Box>
    </Dialog>
  );
};
