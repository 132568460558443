import { httpsCallable } from "firebase/functions";
import { bizFunctions } from "lib/firebase";
import { CustomToken } from "@shared/types/functionParams";

type RequestParams = CustomToken.RequestParams;
type ResponseParams = CustomToken.ResponseParams;

export const getCustomeToken = async (
  requestParams: RequestParams
): Promise<string> => {
  try {
    const func = httpsCallable(bizFunctions, "onCallCustomToken");
    const result = await func(requestParams);

    const { ownerCustomToken } = result.data as ResponseParams;

    return ownerCustomToken;
  } catch (e) {
    return "";
  }
};
