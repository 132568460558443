import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Box, Button, Paper, TextField } from "@mui/material";
import { PublicLayout } from "components/Layout/PublicLayout";
import { bizAuth } from "lib/firebase";
import { sendPasswordResetEmail } from "firebase/auth";
import { useSnackbar } from "context/snackbarContext";
import { FirebaseError } from "firebase/app";

export const ResetPassword = (): JSX.Element => {
  const [mail, setMail] = useState<string>("");
  const [done, setDone] = useState<boolean>(false);
  const { showSnackbar } = useSnackbar();
  const navigate = useNavigate();

  const handleInputChange = (mail: string): void => {
    setMail(mail);
  };

  const handleForgotPassword = async (): Promise<void> => {
    if (!mail) {
      showSnackbar("メールアドレスを入力してください", "error");
    }

    try {
      await sendPasswordResetEmail(bizAuth, mail);
      setDone(true);
    } catch (e) {
      if (e instanceof FirebaseError) {
        if (e.code === "auth/user-not-found") {
          showSnackbar("登録されていないメールアドレスです", "error");
        } else if (e.code === "auth/invalid-email") {
          showSnackbar("正しいメールアドレスを入力してください", "error");
        } else {
          showSnackbar("送信に失敗しました。", "error");
        }
      }
    }
  };

  const handleNavigateToTop = (): void => {
    navigate("/");
  };

  return (
    <PublicLayout title="パスワード再設定">
      <Box className="min-h-screen flex flex-col items-center justify-center ">
        <Box>
          <div
            className="w-[200px] h-8 relative cursor-pointer"
            onClick={handleNavigateToTop}
          >
            <img
              src={`${process.env.PUBLIC_URL}/images/remodelaBiz.png`}
              alt="リモデラBiz"
              className="w-full h-full object-cover absolute top-0 left-0"
            />
          </div>
        </Box>

        {!done ? (
          <Box className="flex flex-col justify-center w-11/12 sm:w-2/5">
            <Paper className="flex flex-col items-center mt-8 py-10">
              <Box className="flex flex-col items-center pb-10">
                <div className="font-bold text-xl pb-2">パスワード再設定</div>
                <div className="w-10 h-1 bg-remodela-green"></div>
              </Box>

              <div className="flex flex-col items-center w-11/12 sm:w-2/3 mb-4">
                <div className="pb-4">
                  ご登録のメールアドレスを入力して、送信ボタンをクリックしてください。その後、メールに記載されたURLをクリックして新しいパスワードを設定してください。
                </div>
                <TextField
                  placeholder="メールアドレス"
                  type="mail"
                  size="small"
                  fullWidth
                  value={mail}
                  onChange={(e) => handleInputChange(e.target.value)}
                />
              </div>

              <Button
                type="submit"
                variant="contained"
                className="px-14 py-3 font-bold bg-remodela-green rounded-md"
                onClick={handleForgotPassword}
              >
                送信
              </Button>
            </Paper>
          </Box>
        ) : (
          <Box className="flex flex-col justify-center w-2/5">
            <Paper className="flex flex-col items-center mt-8 py-10">
              <Box className="flex flex-col items-center pb-10">
                <div className="font-bold text-xl pb-2">送信完了</div>
                <div className="w-10 h-1 bg-remodela-green"></div>
              </Box>

              <div className="flex flex-col items-center w-2/3 mb-4">
                <div className="pb-4">
                  ご登録のメールアドレスにパスワード再設定のメールを送信しました。
                </div>
                <div className="pb-4 text-sm">
                  ※メールを受信できない場合は「迷惑メール」フォルダに振り分けられている可能性がありますので、ご確認ください。
                </div>
              </div>

              <Button
                type="submit"
                variant="contained"
                className="px-14 py-3 font-bold bg-remodela-green rounded-md"
                onClick={handleNavigateToTop}
              >
                トップに戻る
              </Button>
            </Paper>
          </Box>
        )}
      </Box>
    </PublicLayout>
  );
};
