import { ChangeEvent, useEffect, useRef, useState } from "react";
import { getDownloadURL, ref, uploadBytes } from "firebase/storage";
import { Timestamp } from "firebase/firestore";

import { UpdateProjectBySwapFiles } from "@shared/types/functionParams";
import { SwapFile } from "@shared/types/entities/owner/Project";
import { useBackdrop } from "context/backdropContext";
import { useSnackbar } from "context/snackbarContext";
import { fetchBizContractorByContractorId } from "repositories/owner/BizContractor";
import { Project } from "repositories/owner/Project";
import { ownerStorage } from "lib/firebase";
import { getBlobURL } from "../../useCases/storage/contractFile";
import { updateProjectBySwapFiles } from "../../api/updateProjectBySwapFiles";
import { Box, Button, Dialog, Tab, Tabs, Tooltip } from "@mui/material";

interface FileSwapDialogProps {
  open: boolean;
  onClose: () => void;
  project: Project;
}

/**関連資料ダイアログ */
export const FileSwapDialog = ({ open, onClose, project }: FileSwapDialogProps): JSX.Element => {
  const [tabIndex, setTabIndex] = useState<number>(0);
  const [bizContractorName, setBizContractorName] = useState<string>("");
  const fileInputRef = useRef<HTMLInputElement>(null);

  const { setBackdrop } = useBackdrop();
  const { showSnackbar } = useSnackbar();

  useEffect(() => {
    const initBizContractorName = async (): Promise<void> => {
      const bizContractor = await fetchBizContractorByContractorId(project.bizContractorId!);
      setBizContractorName(bizContractor.name);
    };

    initBizContractorName();

    // TODO: nanostoresにあらかじめ登録するようにする
  }, [project.bizContractorId]);

  const handleTabChange = (_: React.SyntheticEvent, tabIndex: number): void => {
    setTabIndex(tabIndex);
  };

  const handleDownloadSwapFile = async (swapOwnerFile: SwapFile): Promise<void> => {
    const blobURL = await getBlobURL(swapOwnerFile.url);
    const link = document.createElement("a");
    link.href = blobURL;
    link.download = swapOwnerFile.name;
    link.click();
    link.remove();
  };

  const uploadFile = {
    /**アップロードボタンクリック → inputクリックしたことにする */
    handleFileSelectButtonClick: (): void => {
      fileInputRef.current?.click();
    },
    /**ファイル選択 → storageに保存+firestoreに保存 */
    handleFileChange: async (event: ChangeEvent<HTMLInputElement>): Promise<void> => {
      if (!event.target.files) {
        return;
      }

      const files: File[] = Array.from(event.target.files);
      if (files.length === 0) {
        return;
      }

      setBackdrop(true);
      try {
        await uploadFile.saveFiles(files);
      } catch (error) {
        console.error("Error reading files:", error);
        showSnackbar("アップロードに失敗しました", "error");
      }

      // ファイル選択をリセット
      event.target.value = "";
      setBackdrop(false);
    },
    /**storageに保存 + firestoreに保存 */
    saveFiles: async (files: File[]): Promise<void> => {
      let swapFiles: SwapFile[] = [];

      const dirPath = `companies/${project.companyId}/projects/${project.id!}/swap_files/biz`;
      const now = new Date();

      for (const file of files) {
        const storageRef = ref(ownerStorage, `${dirPath}/${now.getTime()}_${file.name}`);

        await uploadBytes(storageRef, file);

        const downloadURL = await getDownloadURL(storageRef);

        swapFiles = [
          {
            name: file.name,
            url: downloadURL,
            createdAt: Timestamp.fromDate(now),
          },
          ...swapFiles,
        ];
      }

      const params: UpdateProjectBySwapFiles.RequestParams = {
        swapFiles,
        companyId: project.companyId,
        projectId: project.id!,
      };
      await updateProjectBySwapFiles(params);
    },
  };

  return (
    <Dialog onClose={onClose} open={open}>
      <Box className="flex flex-col items-center p-3 space-y-4">
        <Tabs
          value={tabIndex}
          onChange={handleTabChange}
          centered
          TabIndicatorProps={{
            style: {
              backgroundColor: "#10692A",
            },
          }}
        >
          <Tab className="text-darkBlueGray w-80 normal-case" label={bizContractorName} />
          <Tab className="text-darkBlueGray w-80" label={project.companyName} />
        </Tabs>
      </Box>
      {tabIndex === 0 && (
        <>
          <Box className="flex flex-col p-3 max-h-[500px] overflow-y-auto">
            {project.swapBizFiles?.map((swapBizFile, index) => {
              return (
                <Box key={index}>
                  <Tooltip title="ダウンロード" placement="right">
                    <span
                      className="hover:text-[#0000ff] cursor-pointer"
                      onClick={() => handleDownloadSwapFile(swapBizFile)}
                    >
                      {swapBizFile.name}
                    </span>
                  </Tooltip>
                </Box>
              );
            })}
          </Box>

          <Box className="flex justify-center m-3">
            <Button
              type="submit"
              variant="contained"
              className="px-14 py-3 font-bold bg-remodela-green rounded-md"
              onClick={uploadFile.handleFileSelectButtonClick}
            >
              アップロード（複数選択可能）
            </Button>
            <input type="file" multiple ref={fileInputRef} onChange={uploadFile.handleFileChange} className="hidden" />
          </Box>
        </>
      )}
      {tabIndex === 1 && (
        <>
          <Box className="flex flex-col p-3 max-h-[500px] overflow-y-auto">
            {project.swapOwnerFiles?.map((swapOwnerFile, index) => {
              return (
                <Box key={index}>
                  <Tooltip title="ダウンロード" placement="right">
                    <span
                      className="hover:text-[#0000ff] cursor-pointer"
                      onClick={() => handleDownloadSwapFile(swapOwnerFile)}
                    >
                      {swapOwnerFile.name}
                    </span>
                  </Tooltip>
                </Box>
              );
            })}
          </Box>
        </>
      )}
    </Dialog>
  );
};
