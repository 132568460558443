import { useNavigate } from "react-router-dom";
import { Box, Button } from "@mui/material";
import { PublicLayout } from "components/Layout/PublicLayout";

export const NotFound = (): JSX.Element => {
  const navigate = useNavigate();

  const handleNavigateToTop = (): void => {
    navigate("/");
  };

  return (
    <PublicLayout title="ページが見つかりません">
      <Box className="h-screen flex flex-col items-center justify-center">
        <Box>
          <div className="w-[200px] h-8 relative">
            <img
              src={`${process.env.PUBLIC_URL}/images/remodelaBiz.png`}
              alt="リモデラBiz"
              className="w-full h-full object-cover absolute top-0 left-0"
            />
          </div>
        </Box>
        <Box className="flex flex-col items-center max-w-2xl">
          <div className="text-2xl font-bold py-8 tracking-widest">
            ページが見つかりません
          </div>
          <img
            src={`${process.env.PUBLIC_URL}/images/pageNotFound.png`}
            alt="ページが見つかりません"
          />
          <div className="text-center py-4">
            お探しのページは見つかりませんでした。URLが正しいかどうかご確認いただくか、トップページから情報を探してみてください。
          </div>
          <Button
            variant="contained"
            className="bg-remodela-green font-bold text-xs rounded-lg w-40 h-12 mx-3"
            onClick={handleNavigateToTop}
          >
            トップに戻る
          </Button>
        </Box>
      </Box>
    </PublicLayout>
  );
};
