import { useEffect, useState } from "react";
import { Big } from "big.js";
import { BizEstimate } from "@shared/types/entities/owner/BizEstimate";

type CategorizedEstimates = {
  categoryName: string;
  bizEstimates: BizEstimate[];
};

export type UseCategorizedBizEstimatesProps = {
  categorizedEstimatesList: CategorizedEstimates[];
  /**項目*/
  getItemName: (bizEstimate: BizEstimate) => string;
  /**数量*/
  getQuantity: (bizEstimate: BizEstimate) => string;
  /**単価*/
  getUnitPrice: (bizEstimate: BizEstimate) => string;
  /**金額(小数点以下切り捨て)*/
  getAmount: (bizEstimate: BizEstimate) => string;
  /**現調写真*/
  getFirstGentyoImageURL: (bizEstimate: BizEstimate) => string;
  /**現調写真枚数*/
  getGentyoImageURLCount: (bizEstimate: BizEstimate) => number;
};
export const useCategorizedBizEstimates = (
  bizEstimates: BizEstimate[]
): UseCategorizedBizEstimatesProps => {
  const [categorizedEstimatesList, setCategorizedEstimatesList] = useState<
    CategorizedEstimates[]
  >([]);

  useEffect(() => {
    let categorizedEstimatesList: CategorizedEstimates[] = [];

    for (let categoryNo = 1; ; categoryNo++) {
      const filteredBizEstimates = bizEstimates.filter(
        (bizEstimate) => bizEstimate.categoryNo === categoryNo
      );

      if (filteredBizEstimates.length === 0) {
        break;
      }

      categorizedEstimatesList = [
        ...categorizedEstimatesList,
        {
          categoryName: filteredBizEstimates[0].categoryName || "",
          bizEstimates: filteredBizEstimates,
        },
      ];
    }

    const discountEstimate = bizEstimates.find(
      (bizEstimate) => bizEstimate.categoryNo === 9999
    );
    if (discountEstimate) {
      categorizedEstimatesList = [
        ...categorizedEstimatesList,
        {
          categoryName: discountEstimate.categoryName,
          bizEstimates: [discountEstimate],
        },
      ];
    }

    setCategorizedEstimatesList(categorizedEstimatesList);
  }, [bizEstimates]);

  const getItemName = (bizEstimate: BizEstimate): string => {
    return bizEstimate.itemName;
  };

  const getQuantity = (bizEstimate: BizEstimate): string => {
    return `${bizEstimate.quantity.toLocaleString()}${bizEstimate.unit}`;
  };

  const getUnitPrice = (bizEstimate: BizEstimate): string => {
    return `¥${bizEstimate.unitPrice.toLocaleString()}`;
  };

  const getAmount = (bizEstimate: BizEstimate): string => {
    const unitPrice = bizEstimate.unitPrice;
    const amount = Math.floor(
      Number(Big(unitPrice).times(bizEstimate.quantity))
    );

    return `¥${amount.toLocaleString()}`;
  };

  const getFirstGentyoImageURL = (bizEstimate: BizEstimate): string => {
    const defaultImgUrl = `${process.env.PUBLIC_URL}/images/noImage.png`;
    return bizEstimate.gentyoImages?.[0]?.URL || defaultImgUrl;
  };

  const getGentyoImageURLCount = (bizEstimate: BizEstimate): number => {
    return bizEstimate.gentyoImages?.length || 0;
  };

  return {
    categorizedEstimatesList,
    getItemName,
    getQuantity,
    getUnitPrice,
    getAmount,
    getFirstGentyoImageURL,
    getGentyoImageURLCount,
  };
};

export default useCategorizedBizEstimates;
