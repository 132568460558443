import { httpsCallable } from "firebase/functions";
import { bizFunctions } from "lib/firebase";
import { CreateEstimateInvoiceExcel } from "@shared/types/functionParams";

type RequestParams = CreateEstimateInvoiceExcel.RequestParams;

export const createEstimateInvoiceExcel = async (
  requestParams: RequestParams
): Promise<void> => {
  const func = httpsCallable(bizFunctions, "onCallCreateEstimateInvoiceExcel");

  await func(requestParams);
};
