import { Timestamp } from "firebase/firestore";
import { useStore } from "@nanostores/react";
import { useState, createContext, useContext, ReactNode, useEffect } from "react";
import Big from "big.js";

import { saveBizEstimates } from "../api/saveBizEstimates";
import { updateBizEstimatesNotification } from "../api/updateBizEstimatesNotification";
import { createEstimateInvoiceExcel } from "../api/createEstimateInvoiceExcel";
import { BizConstractorStore } from "store/nanostores/contractorInfo";
import { BizEstimate, GentyoImage, OwnerComment } from "@shared/types/entities/owner/BizEstimate";
import { uploadGentyoImageToStorage } from "../useCases/storage/gentyoImages";
import { BizEstimateValidityPeriod, Project, TaxType } from "@shared/types/entities/owner/Project";
import { TAX_RATE } from "@shared/constants";
import { FireTimestamp } from "utils/firebaseUtil";

type ContextType = {
  categorizedEstimates: CategorizedEstimate[];
  discountEstimate?: BizEstimate;
  validityPeriod: BizEstimateValidityPeriod;
  taxType: TaxType;
  project: Project;

  /**消費税設定の変更(PC版のみ) */
  changeTaxType: (taxType: TaxType) => void;

  /**項目内容の変更 */
  changeItemName: (categoryId: string, rowNo: number, value: string) => void;
  /**数量の変更 */
  changeQuantity: (categoryId: string, rowNo: number, value: string) => void;
  /**単位の変更 */
  changeUnit: (categoryId: string, rowNo: number, value: string) => void;
  /**単価の変更 */
  changeUnitPrice: (categoryId: string, rowNo: number, value: string) => void;
  /**備考の変更 */
  changeNote: (categoryId: string, rowNo: number, value: string) => void;

  /**行の削除 */
  deleteRow(categoryId: string, rowNo: number): void;
  /**行の追加 */
  addRow(categoryId: string): void;
  /**行の入れ替え(PC版のみ) */
  changeRow: (categoryId: string, changedBizEstimateRows: BizEstimateRow[]) => void;

  /**現調写真の追加 */
  addGentyoImages: (categoryId: string, rowNo: number, pendingGentyoImages: PendingGentyoImage[]) => void;
  /**現調写真の削除 */
  deleteGentyoImage: (categoryId: string, rowNo: number, URL: string) => void;
  /**現調写真の1枚目のURL(pending写真) */
  getFirstGentyoImageURL: (row: BizEstimateRow) => string | undefined;
  /**現調写真の枚数(pending写真) */
  getGentyoImageCount: (row: BizEstimateRow) => number;
  /**現調写真(pending写真) */
  getGentyoImages: (row: BizEstimateRow) => PendingGentyoImage[];

  /**カテゴリー(大項目)の追加 */
  addCategory: (categoryName: string) => void;
  /**カテゴリー(大項目)の削除 */
  deleteCategory: (categoryId: string) => void;
  /**カテゴリー名(大項目)の変更  */
  setCategoryName: (categoryName: string, categoryId: string) => void;

  /**小計 */
  getSubTotal: () => number;
  /**消費税 */
  getTax: () => number;
  /**合計金額(小計 + 消費税) */
  getTotal: () => number;

  /**お値引きカテゴリーの[追加]か[更新] */
  editDiscountCategory: (discountAmoount: number) => void;
  /**お値引きカテゴリーの削除 */
  deleteDiscountCategory: () => void;

  /**対象のカテゴリーで1つでも施主承認があるか */
  existsOwnerFixedByCategoryId: (categoryId: string) => boolean;
  /**すべてのカテゴリーで1つでも施主承認があるか */
  existsOwnerFixed: () => boolean;

  /**見積有効期限のラジオボタン切り替え */
  validityPeriodChangeRadio: (validityPeriodType: ValidityPeriodType) => void;
  /**有効期限カレンダー日付適用 */
  validityPeriodSetCalendar: (calendar: FireTimestamp) => void;
  /**有効期限日数適用 */
  validityPeriodSetDays: (days: number) => void;

  /**見積の保存 */
  save: () => Promise<void>;
};

export type ValidityPeriodType = "calendar" | "days";

/**保留中現調写真データ */
export type PendingGentyoImage = {
  file?: File;
  fileName: string;
  URL: string;
  isRegistered: boolean;
};

export type BizEstimateRow = {
  id: string;
  rowNo: number;
  gentyoImages?: GentyoImage[];
  itemName?: string;
  quantity?: number;
  unit?: string;
  unitPrice?: number;
  amount?: number;
  note?: string;
  ownerComments?: OwnerComment[];
  ownerFixed?: boolean;
  pendingGentyoImages?: PendingGentyoImage[]; // データの一時保管領域(画面表示させるためにURLで持つ必要がある)
};

export type CategorizedEstimate = {
  categoryId: string;
  categoryNo: number;
  categoryName: string;
  fixed: boolean;
  jobTypeId?: string;
  bizEstimateRows: BizEstimateRow[];
};

/**お値引き行の初期データ */
function getInitDiscountEstimate(discountAmoount: number): BizEstimate {
  return {
    categoryNo: 9999,
    categoryName: "お値引き",
    jobTypeId: "",
    rowNo: 1,
    gentyoImages: [],
    itemName: "お値引き",
    quantity: 1,
    unit: "式",
    unitPrice: discountAmoount,
    amount: discountAmoount,
    note: "",
    ownerComments: [],
    ownerFixed: false,
  };
}

/**見積行の初期データ */
function getInitBizEstimateRow(rowNo: number = 1): BizEstimateRow {
  return {
    id: crypto.randomUUID(),
    rowNo,
  };
}

/**大項目(カテゴリー)の初期データ */
function getInitCategorizedEstimate(categoryName: string = "クロス張替工事"): CategorizedEstimate {
  return {
    categoryId: crypto.randomUUID(),
    categoryNo: 1,
    categoryName: categoryName,
    fixed: false,
    bizEstimateRows: [getInitBizEstimateRow()],
  };
}

/**見積有効期限の初期データ */
function getInitCalendar(): BizEstimateValidityPeriod {
  const date = new Date();
  date.setMonth(date.getMonth() + 1);
  return { calendar: Timestamp.fromDate(date) };
}

const CategorizedEstimatesContext = createContext<ContextType>({} as ContextType);

export const useCategorizedEstimatesContext = (): ContextType => {
  return useContext(CategorizedEstimatesContext);
};

type CategorizedEstimatesProviderProps = {
  children: ReactNode;
  bizEstimates: BizEstimate[];
  project: Project;
};
export const CategorizedEstimatesProvider = ({
  children,
  bizEstimates,
  project,
}: CategorizedEstimatesProviderProps) => {
  // 消費税設定
  const [taxType, setTaxType] = useState<TaxType>("roundDown");

  // 見積有効期限設定
  const [validityPeriod, setValidityPeriod] = useState<BizEstimateValidityPeriod>(getInitCalendar());

  // カテゴリー毎の見積データ
  const [categorizedEstimates, setCategorizedEstimates] = useState<CategorizedEstimate[]>([
    getInitCategorizedEstimate(),
  ]);

  //お値引き見積データ
  const [discountEstimate, setDiscountEstimate] = useState<BizEstimate>();

  const { userId } = useStore(BizConstractorStore.IDMap);

  useEffect(() => {
    // 税率を適用
    if (project.bizTaxType) {
      setTaxType(project.bizTaxType);
    }

    // 見積有効期限設定を適用
    if (project.bizEstimateValidityPeriod) {
      setValidityPeriod(project.bizEstimateValidityPeriod);
    }

    // 見積データをuseStateに適用
    if (bizEstimates.length > 0) {
      let categorizedEstimates: CategorizedEstimate[] = [];

      // カテゴリー毎にestimateを振り分ける
      for (let categoryNo = 1; ; categoryNo++) {
        const filteredBizEstimates = bizEstimates.filter((bizEstimate) => bizEstimate.categoryNo === categoryNo);

        if (filteredBizEstimates.length === 0) break;

        const bizEstimateRows = filteredBizEstimates.map((bizEstimate) => {
          let bizEstimateRow: BizEstimateRow = {
            id: crypto.randomUUID(),
            rowNo: bizEstimate.rowNo,
            gentyoImages: bizEstimate.gentyoImages,
            itemName: bizEstimate.itemName,
            quantity: bizEstimate.quantity,
            unit: bizEstimate.unit,
            unitPrice: bizEstimate.unitPrice,
            amount: bizEstimate.amount,
            note: bizEstimate.note,
            ownerComments: bizEstimate.ownerComments,
            ownerFixed: bizEstimate.ownerFixed,
          };

          if (bizEstimate.gentyoImages) {
            bizEstimateRow.pendingGentyoImages = bizEstimate.gentyoImages.map((gentyoImage) => ({
              file: undefined,
              fileName: gentyoImage.fileName,
              URL: gentyoImage.URL,
              isRegistered: true,
            }));
          }

          return bizEstimateRow;
        });

        const categorizedEstimate: CategorizedEstimate = {
          categoryId: crypto.randomUUID(),
          categoryNo: filteredBizEstimates[0].categoryNo,
          categoryName: filteredBizEstimates[0].categoryName,
          fixed: bizEstimateRows.every((bizEstimateRow) => bizEstimateRow.ownerFixed === true),
          bizEstimateRows,
        };

        categorizedEstimates = [...categorizedEstimates, categorizedEstimate];
      }
      setCategorizedEstimates(categorizedEstimates);

      // お値引きestimateだけ別表示するため処理を分ける
      const discountEstimate = bizEstimates.find((bizEstimate) => bizEstimate.categoryNo === 9999);

      if (discountEstimate) {
        setDiscountEstimate(discountEstimate);
      }
    }
  }, [bizEstimates, project]);

  // 消費税設定の変更(PC版のみ)
  const changeTaxType = (taxType: TaxType): void => {
    setTaxType(taxType);
  };

  // 項目名の変更
  const changeItemName = (categoryId: string, rowNo: number, value: string): void => {
    const updatetdCategorizedEstimates = categorizedEstimates.map((estimate) => {
      if (estimate.categoryId !== categoryId) {
        return estimate;
      }

      // 更新後のrowsを作成
      const bizEstimateRows = estimate.bizEstimateRows.map((row) => {
        if (row.rowNo !== rowNo) {
          return row;
        }
        return { ...row, itemName: value };
      });

      return {
        ...estimate,
        bizEstimateRows,
      };
    });

    setCategorizedEstimates(updatetdCategorizedEstimates);
  };

  // 数量の変更
  const changeQuantity = (categoryId: string, rowNo: number, value: string): void => {
    const updatetdCategorizedEstimates = categorizedEstimates.map((estimate) => {
      if (estimate.categoryId !== categoryId) {
        return estimate;
      }

      // 更新後のrowsを作成
      const bizEstimateRows = estimate.bizEstimateRows.map((row) => {
        if (row.rowNo !== rowNo) {
          return row;
        }

        // 金額計算(数量*単価) 小数点以下を切り捨て
        // 精度問題で誤差が出るので少数計算ライブラリを使う
        const quantity = Number(value);
        const amount = Big(quantity)
          .times(row.unitPrice || 0)
          .round(0, 0)
          .toNumber();

        return { ...row, quantity, amount };
      });

      return {
        ...estimate,
        bizEstimateRows,
      };
    });

    setCategorizedEstimates(updatetdCategorizedEstimates);
  };

  // 単位の変更
  const changeUnit = (categoryId: string, rowNo: number, value: string): void => {
    const updatetdCategorizedEstimates = categorizedEstimates.map((estimate) => {
      if (estimate.categoryId !== categoryId) {
        return estimate;
      }

      // 更新後のrowsを作成
      const bizEstimateRows = estimate.bizEstimateRows.map((row) => {
        if (row.rowNo !== rowNo) {
          return row;
        }
        return { ...row, unit: value };
      });

      return {
        ...estimate,
        bizEstimateRows,
      };
    });

    setCategorizedEstimates(updatetdCategorizedEstimates);
  };

  // 単価の変更
  const changeUnitPrice = (categoryId: string, rowNo: number, value: string): void => {
    const updatetdCategorizedEstimates = categorizedEstimates.map((estimate) => {
      if (estimate.categoryId !== categoryId) {
        return estimate;
      }

      // 更新後のrowsを作成
      const bizEstimateRows = estimate.bizEstimateRows.map((row) => {
        if (row.rowNo !== rowNo) {
          return row;
        }

        // 金額計算(数量*単価) 小数点以下を切り捨て
        // 精度問題で誤差が出るので少数計算ライブラリを使う
        const unitPrice = Number(value);
        const amount = Big(row.quantity || 0)
          .times(unitPrice)
          .round(0, Big.roundDown)
          .toNumber();

        return { ...row, unitPrice, amount };
      });

      return {
        ...estimate,
        bizEstimateRows,
      };
    });

    setCategorizedEstimates(updatetdCategorizedEstimates);
  };

  // 備考の変更
  const changeNote = (categoryId: string, rowNo: number, value: string): void => {
    const updatetdCategorizedEstimates = categorizedEstimates.map((estimate) => {
      if (estimate.categoryId !== categoryId) {
        return estimate;
      }

      // 更新後のrowsを作成
      const bizEstimateRows = estimate.bizEstimateRows.map((row) => {
        if (row.rowNo !== rowNo) {
          return row;
        }
        return { ...row, note: value };
      });

      return {
        ...estimate,
        bizEstimateRows,
      };
    });

    setCategorizedEstimates(updatetdCategorizedEstimates);
  };

  // 行の削除
  const deleteRow = (categoryId: string, rowNo: number): void => {
    const updatedCategorizedEstimates = categorizedEstimates.map((categorizedEstimate) => {
      if (categorizedEstimate.categoryId !== categoryId) {
        return categorizedEstimate;
      }

      // 行を削除
      const deletedRows = categorizedEstimate.bizEstimateRows.filter(
        (bizEstimateRow) => bizEstimateRow.rowNo !== rowNo
      );

      // indexの振り直し
      const updatedRows = deletedRows.map((bizEstimateRow, index) => ({
        ...bizEstimateRow,
        rowNo: index + 1,
      }));

      return {
        ...categorizedEstimate,
        bizEstimateRows: updatedRows,
      };
    });

    setCategorizedEstimates(updatedCategorizedEstimates);
  };

  // 行の追加
  const addRow = (categoryId: string): void => {
    const addedCategorizedEstimates = categorizedEstimates.map((categorizedEstimate) => {
      if (categorizedEstimate.categoryId !== categoryId) {
        return categorizedEstimate;
      }

      if (categorizedEstimate.bizEstimateRows.length === 0) {
        categorizedEstimate.bizEstimateRows = [getInitBizEstimateRow()];
        return categorizedEstimate;
      }

      const maxRowNo = Math.max(...categorizedEstimate.bizEstimateRows.map((row) => row.rowNo));

      categorizedEstimate.bizEstimateRows = [
        ...categorizedEstimate.bizEstimateRows,
        getInitBizEstimateRow(maxRowNo + 1),
      ];

      return categorizedEstimate;
    });

    setCategorizedEstimates(addedCategorizedEstimates);
  };

  // 行の入れ替え(PC版のみ)
  const changeRow = (categoryId: string, changedBizEstimateRows: BizEstimateRow[]): void => {
    // rowNoの書き換え
    changedBizEstimateRows.forEach((changedBizEstimateRow, index) => {
      changedBizEstimateRow.rowNo = index + 1;
    });

    // 行の入れ替え
    const changedCategorizedEstimates = categorizedEstimates.map((categorizedEstimate) => {
      if (categorizedEstimate.categoryId !== categoryId) {
        return categorizedEstimate;
      }

      categorizedEstimate.bizEstimateRows = changedBizEstimateRows;
      return categorizedEstimate;
    });

    setCategorizedEstimates(changedCategorizedEstimates);
  };

  // 現調写真の追加(pending写真)
  const addGentyoImages = (categoryId: string, rowNo: number, pendingGentyoImages: PendingGentyoImage[]): void => {
    const updatedCategorizedEstimates = categorizedEstimates.map((categorizedEstimate) => {
      if (categorizedEstimate.categoryId !== categoryId) {
        return categorizedEstimate;
      }

      const updatedRows = categorizedEstimate.bizEstimateRows.map((row) => {
        if (row.rowNo !== rowNo) {
          return row;
        }

        const updatedGentyoImages = row.pendingGentyoImages
          ? [...row.pendingGentyoImages, ...pendingGentyoImages]
          : [...pendingGentyoImages];

        return {
          ...row,
          pendingGentyoImages: updatedGentyoImages,
        };
      });

      return {
        ...categorizedEstimate,
        bizEstimateRows: updatedRows,
      };
    });

    setCategorizedEstimates(updatedCategorizedEstimates);
  };

  // 現調写真の削除(pending写真)
  const deleteGentyoImage = (categoryId: string, rowNo: number, URL: string): void => {
    const updatedCategorizedEstimates = categorizedEstimates.map((categorizedEstimate) => {
      if (categorizedEstimate.categoryId !== categoryId) {
        return categorizedEstimate;
      }

      const updatedRows = categorizedEstimate.bizEstimateRows.map((row) => {
        if (row.rowNo !== rowNo) {
          return row;
        }

        const updatedGentyoImages = row.pendingGentyoImages?.filter((iamge) => iamge.URL !== URL);

        return {
          ...row,
          pendingGentyoImages: updatedGentyoImages,
        };
      });

      return {
        ...categorizedEstimate,
        bizEstimateRows: updatedRows,
      };
    });

    setCategorizedEstimates(updatedCategorizedEstimates);
  };

  // 現調写真の1枚目のURL(pending写真)
  const getFirstGentyoImageURL = (row: BizEstimateRow): string | undefined => {
    return row.pendingGentyoImages?.[0]?.URL;
  };

  // 現調写真の枚数(pending写真)
  const getGentyoImageCount = (row: BizEstimateRow): number => {
    if (!row.pendingGentyoImages) {
      return 0;
    }

    return row.pendingGentyoImages.length;
  };

  // 現調写真(pending写真)
  const getGentyoImages = (row: BizEstimateRow): PendingGentyoImage[] => {
    return row.pendingGentyoImages || [];
  };

  // カテゴリー(大項目)の追加
  const addCategory = (categoryName: string): void => {
    if (categorizedEstimates.length === 0) {
      setCategorizedEstimates([getInitCategorizedEstimate(categoryName)]);
      return;
    }

    const maxCategoryNo = Math.max(
      ...categorizedEstimates.map((categorizedEstimate) => categorizedEstimate.categoryNo)
    );

    setCategorizedEstimates([
      ...categorizedEstimates,
      {
        ...getInitCategorizedEstimate(categoryName),
        categoryNo: maxCategoryNo + 1,
      },
    ]);
  };

  // カテゴリー(大項目)の削除
  const deleteCategory = (categoryId: string): void => {
    const deletedCategorizedEstimates = categorizedEstimates.filter(
      (categorizedEstimate) => categorizedEstimate.categoryId !== categoryId
    );

    const incrementedCategorizedEstimates = deletedCategorizedEstimates.map((categorizedEstimate, index) => {
      categorizedEstimate.categoryNo = index + 1;
      return categorizedEstimate;
    });

    setCategorizedEstimates(incrementedCategorizedEstimates);
  };

  // カテゴリー名(大項目)の変更
  const setCategoryName = (categoryName: string, categoryId: string): void => {
    const updatedCategorizedEstimates = categorizedEstimates.map((categorizedEstimate) => {
      if (categorizedEstimate.categoryId !== categoryId) {
        return categorizedEstimate;
      }

      return {
        ...categorizedEstimate,
        categoryName,
      };
    });

    setCategorizedEstimates(updatedCategorizedEstimates);
  };

  // 小計
  const getSubTotal = (): number => {
    let subTotal = 0;

    categorizedEstimates.forEach((categorizedEstimate) => {
      categorizedEstimate.bizEstimateRows.forEach((bizEstimateRow) => {
        subTotal += bizEstimateRow.amount || 0;
      });
    });

    if (discountEstimate) {
      subTotal += discountEstimate.amount!;
    }

    return subTotal;
  };

  // 消費税
  const getTax = (): number => {
    if (taxType === "zero") {
      return 0;
    }

    let roundedTax = 0;

    const subTotal = getSubTotal();
    const tax = Big(subTotal).times(TAX_RATE);

    if (taxType === "roundUp") {
      roundedTax = tax.round(0, Big.roundUp).toNumber();
    }

    if (taxType === "roundDown") {
      roundedTax = tax.round(0, Big.roundDown).toNumber();
    }

    return roundedTax;
  };

  // 合計金額(小計 + 消費税)
  const getTotal = (): number => {
    return getSubTotal() + getTax();
  };

  // お値引きのカテゴリーの（追加/更新)
  const editDiscountCategory = (discountAmoount: number): void => {
    if (!discountEstimate) {
      const initDiscountEstimate = getInitDiscountEstimate(discountAmoount);
      setDiscountEstimate(initDiscountEstimate);
      return;
    }

    const newDiscountAmount = discountEstimate.unitPrice + discountAmoount;

    setDiscountEstimate({
      ...discountEstimate,
      unitPrice: newDiscountAmount,
      amount: newDiscountAmount,
    });
  };

  // お値引きのカテゴリーの削除
  const deleteDiscountCategory = (): void => {
    setDiscountEstimate(undefined);
  };

  // 対象のカテゴリーで1つでも施主承認があるか
  const existsOwnerFixedByCategoryId = (categoryId: string): boolean => {
    const categorizedEstimate = categorizedEstimates.find((estimate) => estimate.categoryId === categoryId);

    if (!categorizedEstimate) {
      return false;
    }

    for (const bizEstimateRow of categorizedEstimate.bizEstimateRows) {
      if (bizEstimateRow.ownerFixed) {
        return true;
      }
    }

    return false;
  };

  // すべてのカテゴリーで1つでも施主承認があるか
  // 1度承認した後に変更させたくない項目に対して使用する(変更されると金額が変わるため)
  const existsOwnerFixed = (): boolean => {
    for (const categorizedEstimate of categorizedEstimates) {
      for (const bizEstimateRow of categorizedEstimate.bizEstimateRows) {
        if (bizEstimateRow.ownerFixed) {
          return true;
        }
      }
    }

    return false;
  };

  // 見積有効期限のラジオボタン切り替え
  const validityPeriodChangeRadio = (validityPeriodType: ValidityPeriodType): void => {
    if (validityPeriodType === "calendar") {
      project.bizEstimateValidityPeriod?.calendar
        ? setValidityPeriod(project.bizEstimateValidityPeriod)
        : setValidityPeriod(getInitCalendar());
    }

    if (validityPeriodType === "days") {
      project.bizEstimateValidityPeriod?.days
        ? setValidityPeriod(project.bizEstimateValidityPeriod)
        : setValidityPeriod({ days: undefined });
    }
  };

  // 有効期限カレンダー日付適用
  const validityPeriodSetCalendar = (calendar: FireTimestamp) => {
    setValidityPeriod({ calendar });
  };

  // 有効期限日数適用
  const validityPeriodSetDays = (days: number): void => {
    setValidityPeriod({ days });
  };

  // 見積もりの保存
  const save = async (): Promise<void> => {
    const companyId = project.companyId;
    const projectId = project.id!;

    const processGentyoImages = async (bizEstimateRow: BizEstimateRow): Promise<GentyoImage[]> => {
      const gentyoImages: GentyoImage[] = [];
      if (!bizEstimateRow.pendingGentyoImages) {
        return gentyoImages;
      }

      const tasks = bizEstimateRow.pendingGentyoImages.map(async (pendingGentyoImage) => {
        let URL = pendingGentyoImage.URL;
        // firestoreにまだ登録されていない写真
        if (!pendingGentyoImage.isRegistered) {
          URL = await uploadGentyoImageToStorage(
            companyId,
            projectId,
            pendingGentyoImage.file!,
            pendingGentyoImage.fileName
          );
        }

        const gentyoImage: GentyoImage = {
          fileName: pendingGentyoImage.fileName,
          URL,
        };

        return gentyoImage;
      });

      const results = await Promise.all(tasks);
      return results.filter((result) => result !== null) as GentyoImage[];
    };

    const promises: Promise<BizEstimate>[] = [];
    for (const categorizedEstimate of categorizedEstimates) {
      for (const bizEstimateRow of categorizedEstimate.bizEstimateRows) {
        const promise = processGentyoImages(bizEstimateRow).then((gentyoImages) => {
          const bizEstimate: BizEstimate = {
            categoryNo: categorizedEstimate.categoryNo,
            categoryName: categorizedEstimate.categoryName,
            jobTypeId: categorizedEstimate.jobTypeId || "",
            rowNo: bizEstimateRow.rowNo,
            gentyoImages,
            itemName: bizEstimateRow.itemName || "",
            quantity: bizEstimateRow.quantity || 0,
            unit: bizEstimateRow.unit || "",
            unitPrice: bizEstimateRow.unitPrice || 0,
            amount: bizEstimateRow.amount || 0,
            ownerComments: bizEstimateRow.ownerComments || [],
            ownerFixed: bizEstimateRow.ownerFixed || false,
          };

          if (bizEstimateRow.note) {
            bizEstimate.note = bizEstimateRow.note;
          }

          return bizEstimate;
        });

        promises.push(promise);
      }
    }

    const bizEstimates: BizEstimate[] = await Promise.all(promises);

    if (discountEstimate) {
      bizEstimates.push(discountEstimate);
    }

    // bizEstimate,project保存
    await saveBizEstimates({
      bizEstimates,
      taxType,
      validityPeriod,
      companyId,
      projectId,
      bizUserId: userId,
    });

    // もしステータスが提案中か工事中の場合、施主に見積が変更された旨のメールを送信
    updateBizEstimatesNotification({ companyId, projectId });

    // 見積書と請求書の作成
    createEstimateInvoiceExcel({ companyId, projectId });
  };

  return (
    <CategorizedEstimatesContext.Provider
      value={{
        categorizedEstimates,
        discountEstimate,
        validityPeriod,
        taxType,
        project,

        changeTaxType,

        changeItemName,
        changeQuantity,
        changeUnit,
        changeUnitPrice,
        changeNote,

        deleteRow,
        addRow,
        changeRow,

        addGentyoImages,
        getFirstGentyoImageURL,
        getGentyoImageCount,
        getGentyoImages,
        deleteGentyoImage,

        addCategory,
        deleteCategory,
        setCategoryName,

        getSubTotal,
        getTax,
        getTotal,

        editDiscountCategory,
        deleteDiscountCategory,

        existsOwnerFixedByCategoryId,
        existsOwnerFixed,

        validityPeriodChangeRadio,
        validityPeriodSetCalendar,
        validityPeriodSetDays,

        save,
      }}
    >
      {children}
    </CategorizedEstimatesContext.Provider>
  );
};
