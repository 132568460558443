import Big from "big.js";

import type { TaxType, Status, BizEstimateValidityPeriod } from "../../@shared/types/entities/owner/Project";
import { type BizEstimate } from "../../@shared/types/entities/owner/BizEstimate";
import { TAX_RATE, type BizProjectStatus } from "../constants";

//---フロントでのみ利用---//
const statusMap = new Map<Status, BizProjectStatus | undefined>([
  ["", undefined],
  ["下書き中", undefined],
  ["オファー中", undefined],
  ["点検中", undefined],
  ["オファー可能", "提案中"],
  ["受付中", "受付中"],
  ["見積中", "見積中"],
  ["工事中", "工事中"],
  ["検収待ち", "検収待ち"],
  ["完了", "完了"],
  ["取下げ", "取下げ"],
  ["期限切れ", "期限切れ"],
]);

function getOwnerStatus(bizStatus: BizProjectStatus): Status {
  for (const [key, value] of statusMap.entries()) {
    if (value === bizStatus) {
      return key;
    }
  }
  throw new Error("Status not found");
}

export function getOwnerStatusesByBizStatuses(bizStatuses: BizProjectStatus[]): Status[] {
  let ownerProjectStatus: Status[] = [];

  bizStatuses.forEach((bizStatus) => {
    const ownerStatus = getOwnerStatus(bizStatus);
    if (ownerStatus) {
      ownerProjectStatus = [...ownerProjectStatus, ownerStatus];
    }
  });

  return ownerProjectStatus;
}

/**消費税表示 */
export const displayTaxType = (bizTaxType?: TaxType): string => {
  if (bizTaxType === "zero") {
    return "消費税0%";
  }

  if (bizTaxType === "roundDown") {
    return "消費税10%(切捨て)";
  }

  if (bizTaxType === "roundUp") {
    return "消費税10%(切上げ)";
  }

  return "消費税0%";
};
//---フロントでのみ利用---//

/**契約書関連 */
export const Contract = {
  templatePath: {
    estimate: "biz/template/見積書.xlsx",
    invoice: "biz/template/請求書.xlsx",
  },
  savePath: {
    getOrderPath: (companyId: string, projectId: string, fileName: string): string => {
      return `biz/companies/${companyId}/projects/${projectId}/contract/${fileName}`;
    },
    getEstimatePath: (companyId: string, projectId: string): string => {
      return `biz/companies/${companyId}/projects/${projectId}/contract/見積書.xlsx`;
    },
    getEstimateOwnerNamePath: (companyId: string, projectId: string): string => {
      return `biz/companies/${companyId}/projects/${projectId}/contract/見積書(施主名義).xlsx`;
    },
    getInvoicePath: (companyId: string, projectId: string): string => {
      return `biz/companies/${companyId}/projects/${projectId}/contract/請求書.xlsx`;
    },
  },
  canDownload: (status: string) => ({
    order: ["工事中", "検収待ち", "完了"].includes(status),
    estimate: ["見積中", "オファー可能", "工事中", "検収待ち", "完了"].includes(status),
    invoice: ["検収待ち", "完了"].includes(status),
  }),
};

/**電子印鑑画像 */
export const DigitalStamp = {
  /**storageパス */
  getPath: (bizContractorId: string, fileName: string): string => {
    return `biz/bizContractors/${bizContractorId}/digitalStamp/${fileName}`;
  },
};

/**料金表(PDF/画像) */
export const PriceListProcess = {
  /**storageパス */
  getPath: (bizContractorId: string, fileName: string): string => {
    return `biz/bizContractors/${bizContractorId}/priceList/${fileName}`;
  },
};

/**見積金額関連のオブジェクトを返す */
export const BizEstimateCalc = (bizEstimates: BizEstimate[], bizTaxType?: TaxType) =>
  ({
    getTotalAmount: bizEstimates.reduce((acc, cur) => {
      return acc + cur.amount;
    }, 0),
    getTaxAmount: function (): number {
      const taxAmount = Number(Big(this.getTotalAmount).times(TAX_RATE));
      if (bizTaxType === "roundUp") return Math.ceil(taxAmount);
      if (bizTaxType === "roundDown") return Math.floor(taxAmount);
      return 0;
    },
    getIncludeTaxTotalAmount: function (): number {
      return this.getTotalAmount + this.getTaxAmount();
    },
  } as const);

/**見積有効期限 */
export const getValidityPeriod = (bizEstimateValidityPeriod?: BizEstimateValidityPeriod): string | Date => {
  if (bizEstimateValidityPeriod?.calendar) {
    return bizEstimateValidityPeriod.calendar.toDate().toLocaleDateString();
  }

  if (bizEstimateValidityPeriod?.days) {
    return `見積後${bizEstimateValidityPeriod.days.toString()}日`;
  }

  return "";
};
