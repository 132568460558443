import AddIcon from "@mui/icons-material/Add";

import { EstimatesByCategory } from "./EstimatesByCategory";
import { useCategorizedEstimatesContext } from "features/estimates/context/categorizedEstimatesContext";

const AddCategory = (): JSX.Element => {
  const { addCategory } = useCategorizedEstimatesContext();
  return (
    <div className="flex justify-end">
      <div
        className="flex items-center cursor-pointer"
        onClick={() => {
          addCategory("");
        }}
      >
        <AddIcon className="text-3xl" />
        <div className="font-bold text-sm">カテゴリーを追加する</div>
      </div>
    </div>
  );
};

export const EstimatesArea = (): JSX.Element => {
  const { categorizedEstimates } = useCategorizedEstimatesContext();

  return (
    <>
      {categorizedEstimates.map((categorizedEstimate) => (
        <EstimatesByCategory
          key={categorizedEstimate.categoryId}
          categorizedEstimate={categorizedEstimate}
        />
      ))}

      <AddCategory />
    </>
  );
};
