import {
  ref,
  uploadBytes,
  getDownloadURL,
  StorageReference,
} from "firebase/storage";
import { ownerStorage } from "lib/firebase";

function getStorageRef(
  companyId: string,
  projectId: string,
  fileName: string
): StorageReference {
  return ref(
    ownerStorage,
    `/biz/companies/${companyId}/projects/${projectId}/gentyoImages/${fileName}`
  );
}

export async function uploadGentyoImageToStorage(
  companyId: string,
  projectId: string,
  file: File,
  fileName: string
): Promise<string> {
  const fileRef = getStorageRef(companyId, projectId, fileName);

  await uploadBytes(fileRef, file);
  return await getDownloadURL(fileRef);
}
